import React from 'react';
import Task from './Task';
import { standardizeStatus, formatDate } from '../../../utilities/utilities';
let moment = require('moment-business-days');

moment.updateLocale('us', {
    workingWeekdays: [1, 2, 3, 4, 5]
});

const AgreementTask: React.FC<{
    label?   : string;
    status?  : string;
    children?: any;
    date     : any;
    service  : "gas" | "electric";
}> = ({
    label = "Agreement for Service",
    status,
    children,
    date,
    service,
}): any => {{
    const currentDate     = moment();
    const propDate        = moment(date);

    const dueDate         = service === "electric" ? propDate.businessAdd(5) : propDate;
    const businessDayDiff = currentDate.businessDiff(dueDate, 'days'); // this handles checks for due_today, due_soon, & overdue
    const diffTime        = Math.abs(currentDate - dueDate);
    const dayDiff         = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const longDayDiffCheck = currentDate.businessDiff(propDate, 'days'); // this accounts for the data difference for expired soon and expired
    const expiredSoonDiffTime = Math.abs(currentDate - propDate);
    const expiredSoonDayDiff = Math.ceil(expiredSoonDiffTime / (1000 * 60 * 60 * 24) - 1);

    const isOverdue     = moment().isAfter(dueDate) ? true : false;

    const dueSoonRange    = {
        gas         :   { dueSoon : 5, expireSoon  :  60,  expired     :   90 },
        electric    :   { dueSoon : 5, expireSoon  :  150, expired     :   180 },
    };

    const FORMAT_USER    = 'ddd. MMM Do, YYYY';

    const calcuateDataStatus = () => {
        if(date){
            // if dueDate is today
            // debugger;
            if(businessDayDiff === 0){
                return "due_today";
            }
            if(businessDayDiff >= 1 && isOverdue) {
                if(longDayDiffCheck >= dueSoonRange[service].expired){
                    return "expired";
                }
                else if(longDayDiffCheck >= dueSoonRange[service].expireSoon){
                    return "expiring_soon";
                }
                else {
                    return "overdue";
                }
            } else {
                // if dueDate is 2 or less business days away
                if(businessDayDiff <= dueSoonRange[service].dueSoon && !isOverdue){
                    return "due_soon";
                }
            }
        }
        return "to_do";

    }

    const getStatus = () => {
        // status options: not_started, to_do, due_soon, due_today, overdue, expiring_soon, expired, approved
        switch(status && standardizeStatus(status)){
            case "completed":
                return "approved";
            case "in_progress":
                return calcuateDataStatus();
            case "to_do":
                return "not_started";
            default:
                return "not_started";
        };
    };
    const getStatusColor = () => {
        switch(getStatus()){
            case "approved":
                return "green";
            case "due_soon":
            case "due_today":
                return "yellow";
            case "overdue":
            case "expiring_soon":
            case "expired":
                return "red";
            case "to_do":
                return "default";
            case "not_started":
            default:
                return "grey";
        };
    };
    const getIcon = () => {
        switch(getStatus()){
            case "approved":
                return "completed";
            case "due_soon":
            case "due_today":
            return "alert";
            case "expired":
            case "overdue":
            case "expiring_soon":
                return "emergency";
            case "to_do":
                return "in_progress";
            case "not_started":
                return "future";
        };
    };
    const getIconAltText = () => {
		switch(getIcon()){
			case "completed":
                return "Approved";
            case "alert":
                return "Due Soon/Upcoming";
            case "emergency":
                return "Overdue";
            case "in_progress":
                return "To-Do";
            case "future":
                return "Future";
		};
	};
    const getMessage = () => {
        switch(getStatus()){
            case "approved":
                return messages.approved;
            case "due_soon":
                return messages.due_soon;
            case "due_today":
                return messages.due_today;
            case "overdue":
                return messages.overdue;
            case "to_do":
                return messages.to_do;
            case "expiring_soon":
                return messages.expiring_soon;
            case "expired":
                return messages.expired;
            case "not_started":
            default:
                return messages.not_started;
        };
    };

    const messages = {
        approved: `Your Agreement for Service has been approved.`,
        due_soon: `Your Agreement for Service is due in ${dayDiff} day${dayDiff > 1 ? `s`: ``}. Please review and approve to avoid project delays.`,
        due_today: `Your Agreement for Service is due today. Please review and approve to avoid project delays.`,
        overdue: `Your Agreement for Service was due on  ${formatDate(dueDate)}. Please review and approve to avoid further project delays.`,
        expiring_soon: `Your Agreement for Service is expiring on ${formatDate(propDate.add(expiredSoonDayDiff + 1, 'days'))}. Please contact your planner by ${formatDate(propDate.subtract(1, 'days'))} or your work order will be canceled.`,
        expired: `Your Agreement for Service has expired. Please contact your planner if you want to continue your project.`,
        to_do: service === "electric" ? `Your Agreement for Service is ready to be approved. Please review and approve to avoid project delays.` : `Your Agreement for Service has not been submitted.`,
        not_started: `Your Agreement for Service is not ready for review.`,        
       
        not_ready: `Your Agreement for Service is not ready for review.`,
    };

    return(
        <Task
            className   = "agreement-task"
            statusColor = {getStatusColor()}
            status      = {getStatus()}
            label       = {label}
            icon        = {getIcon()}
            iconAltText = {getIconAltText() || null}
            message     = {getMessage() || null}
        >
            {children}
        </Task>
    );
}};

export default AgreementTask;
