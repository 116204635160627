import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./FullPageSpinner.scss";

const FullPageSpinner = () => {
  return (
    <div className="full-page-spinner-container">
      <FontAwesomeIcon
        className="full-page-spinner-container__icon"
        icon={faSpinner}
        spin
      />
    </div>
  );
};

export default FullPageSpinner;
