import React from 'react';
import { NavLink} from "react-router-dom";
import DTEIcon from '../../DTEIcon/DTEIcon';
import ButtonPrimary from '../../common/ButtonPrimary/ButtonPrimary';
import "./FormPageHeader.scss";

const FormPageHeader = ({siteTitle, page}:{ siteTitle: string; page: string; }) => {
    
    const getFormTitle = () => {
        switch(page) {
            case "/change-password":
                return "Forgot Your Password?";
            case "/reset-password":
                return "Confirm Password Reset";
            case "/start-new-service":
                return "Start New Project";
        }
    }

    return (
        <header className="site-header-wrapper ">
            <div className="main-header-wrapper formpage">
                <div className="branding-wrapper">
                    <div className="logo-wrapper">
                        <NavLink
                            to="/"
                            activeClassName="logo"
                            exact
                        >
                            <DTEIcon />
                        </NavLink>
                    </div>
                    <div className="vertical-line" />
                    <div className="site-title-wrapper desktop">
                        <NavLink
                            to="/"
                            activeClassName="site-title"
                            exact
                        >
                            <span className="site-title">{siteTitle}</span>
                        </NavLink>
                    </div>
                </div>
                <div className="formpage-title-wrapper">
                    {getFormTitle()}
                </div>
                <div className="controls-wrapper">
                    <div className="formpage-controls-wrapper">
                        <ButtonPrimary href="/">Cancel</ButtonPrimary>
                    </div>
                </div>
            </div>

        </header>
    );
};

export default FormPageHeader;