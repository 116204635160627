import React from "react";
import "./AccountSettingsPage.scss";
import ProfileInfoCard from "./ProfileInfoCard/ProfileInfoCard"
import NotificationSettingsCard from "./NotificationSettingsCard/NotificationSettingsCard"

const AccountSettingsPage = (): React.ReactElement => {
  document.title = "CMC - My Profile & Notifications";

  return (
    <div className="account-settings-page narrow">
    <main>
      <h1 className="account-settings-page__header">My Profile & Notifications</h1>
      <ProfileInfoCard/>
      <NotificationSettingsCard />
    </main>
  </div>
  );
};

export default AccountSettingsPage;
