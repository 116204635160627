import React from "react";
import { ProjectListItemProps as ProjectListItemData } from "../../../../types/ProjectListItem";
import { AccessLevel } from "../../../../types/accessLevel";
import { formatAddress } from "../../../../utilities/utilities"
import Input from "../../../common/Input/Input"
import "./ProjectListItem.scss";

interface ProjectListItemProps {
  checkboxHandler: (id: string) => void;
  selectHandler: (id: string, accessLevel: AccessLevel) => void;
}

const ProjectListItem = (
  props: ProjectListItemData & ProjectListItemProps
): React.ReactElement => {
  return (
    <div className="work-order-list-item">
      <div className="work-order-list-item__checkbox">
        <Input
          type="checkbox"
          checked={props.selected}
          onClick={() => props.checkboxHandler(props.premiseID || "")}
        />
        </div>


      <div className="work-order-list-item__development-name">
        {formatAddress(props.projectAddress)}
      </div>
      <div className="work-order-list-item__electric-number">
        {props.electricNum ? props.electricNum : null}
      </div>
      <div className="work-order-list-item__gas-number">
        {props.gasNum ? props.gasNum : null}
      </div>
      <div className="work-order-list-item__user-role">
        <div className="select-wrapper">
          <select
            value={props.userRole}
            onChange={e => props.selectHandler(props.premiseID || "", e.target.value)}
            id={props.premiseID}
            data-role={props.userRole}
          >
            <option value="View">View Only</option>
            <option value="Edit">View &amp; Edit</option>
            <option value="Invite">View, Edit &amp; Invite</option>
          </select>  
        </div>     
      </div>     
    </div>
  );
};

export default ProjectListItem;
