import React from "react";
import checkMarkIcon from "../../../assets/checkmark.svg";
import minusIcon from "../../../assets/minus.svg";
import "./AccessLevelChartFuture.scss";

const AccessLevelChartFuture: React.FunctionComponent = (): React.ReactElement => {
  const checkMark = <img src={checkMarkIcon} alt="check mark" />;
  const minus = <img src={minusIcon} alt="check mark" />;

  return (
    <React.Fragment>
      <table className="access-level-chart-future">
        <thead>
          <tr>
            <th />
            <th>Administrator</th>
            <th>View, Edit &amp; Invite</th>
            <th>View &amp; Edit</th>
            <th>View Only</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Send Emails</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
          </tr>
          <tr>
            <td>View Contacts</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
          </tr>
          <tr>
            <td>View Project Status</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
          </tr>
          <tr>
            <td>View Task Status</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
          </tr>
          <tr>
            <td>Schedule Meter Installation</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{minus}</td>
          </tr>
          <tr>
            <td>Update Site Requirements</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{minus}</td>
          </tr>
          <tr>
            <td>Update Task Statuses</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{minus}</td>
          </tr>
          <tr>
            <td>Upload Documents</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{minus}</td>
          </tr>
          <tr>
            <td>Invite New Contacts</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{minus}</td>
            <td>{minus}</td>
          </tr>
          <tr>
            <td>Make Payments</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{minus}</td>
            <td>{minus}</td>
          </tr>
          <tr>
            <td>Approve Agreements</td>
            <td>{checkMark}</td>
            <td>{minus}</td>
            <td>{minus}</td>
            <td>{minus}</td>
          </tr>
          <tr>
            <td>Cancel Project</td>
            <td>{checkMark}</td>
            <td>{minus}</td>
            <td>{minus}</td>
            <td>{minus}</td>
          </tr>
          <tr>
            <td>Create New Project</td>
            <td>{checkMark}</td>
            <td>{minus}</td>
            <td>{minus}</td>
            <td>{minus}</td>
          </tr>
        </tbody>
      </table>

      <div className="access-level-chart-future-mobile">
        <table>
          <thead>
            <tr>
              <th>Administrator</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Send Emails</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>View Contacts</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>View Project Status</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>View Task Status</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Schedule Meter Installation</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Update Site Requirements</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Update Task Statuses</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Upload Documents</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Invite New Contacts</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Make Payments</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Approve Agreements</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Cancel Project</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Create New Project</td>
              <td>{checkMark}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>View, Edit &amp; Invite</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Send Emails</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>View Contacts</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>View Project Status</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>View Task Status</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Schedule Meter Installation</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Update Site Requirements</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Update Task Statuses</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Upload Documents</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Invite New Contacts</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Make Payments</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Approve Agreements</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Cancel Project</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Create New Project</td>
              <td>{minus}</td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>View &amp; Edit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Send Emails</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>View Contacts</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>View Project Status</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>View Task Status</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Schedule Meter Installation</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Update Site Requirements</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Update Task Statuses</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Upload Documents</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Invite New Contacts</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Make Payments</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Approve Agreements</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Cancel Project</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Create New Project</td>
              <td>{minus}</td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>View Only</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Send Emails</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>View Contacts</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>View Project Status</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>View Task Status</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Schedule Meter Installation</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Update Site Requirements</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Update Task Statuses</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Upload Documents</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Invite New Contacts</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Make Payments</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Approve Agreements</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Cancel Project</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Create New Project</td>
              <td>{minus}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default AccessLevelChartFuture;
