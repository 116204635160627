import React, { useState } from "react";
import axios from "axios";
import getDomain from "../../services/getDomain";
import Card from "../common/Card/Card";
import ButtonPrimary from "../common/ButtonPrimary/ButtonPrimary";
import "./ForgotPasswordsPage.scss";

const ForgotPasswordPage = (): React.ReactElement => {
  document.title = "CMC - Forgot Your Password?";

  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const requestReset = () => {
    axios
      .post(
        `${getDomain()}api/user/password`,
        {
          url: "/api/user/password",
          emailAddress: email
        },
        { withCredentials: true }
      )
      .then(r => {
        console.log("- resp", r.data);
        setSuccess(true);
      })
      .catch(e => {
        console.log("- error", e);
        setError(true);
      });
  };

  const blurHandler = () => {
    if (!email.includes("@") && email[email.length - 1] !== "@") {
      setInvalidEmail(true);
    } else {
      setInvalidEmail(false);
    }
  };

  const SuccessMessage = (
    <>
      <h4>Email Sent!</h4>
      <p>
        An email has been sent to the registed addrerss attached to your
        account. To reset your password, please check the account for the reset
        request.
      </p>
      <p>
        Make sure that the email address <button className="link-button">dte-energy@dteenergy.com</button> is added to
        your contacts. the email may take a few minutetst to arrive. If you
        don’t see it in your inbox, please check your spam folder.
      </p>
      <p>
        Note: if there is a problem with the email address we have on file, we
        will be unable to send your sign in information.
      </p>
      <hr />
      <ButtonPrimary href="/">Return to Sign In</ButtonPrimary>
    </>
  );

  return (
    <>
      <div className="forgot-password-page narrow">
        <main>
          <Card>
            {success ? (
              SuccessMessage
            ) : (
              <>
                <h4>Did you forget your password?</h4>
                <p>
                  Please enter the email address associated with your DTE online
                  account. You will receive an email with a link to reset your
                  password.
                </p>
                <label>
                  Email*
                  <input
                    type="text"
                    placeholder="example@example.com"
                    onChange={e => setEmail(e.target.value)}
                    onBlur={blurHandler}
                  />
                  <span className="email-error">
                    {invalidEmail ?
                      "Please provide a valid email address. (ex:name@email.com)":
                      error &&
                      "We are unable to verify your email. Please try again."}
                  </span>
                </label>
                <hr />
                <ButtonPrimary disabled={email === ""} onClick={() => requestReset()}>
                  Send Email
                </ButtonPrimary>
              </>
            )}
          </Card>
          <p>
            If you have any questions or concerns, please contact us at
            800.338.0178.
          </p>
        </main>
      </div>
    </>
  );
};
export default ForgotPasswordPage;
