import React, { useContext, useState, useEffect } from 'react';
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import { HamburgerSlider } from "react-animated-burgers";
import getDomain from '../../services/getDomain';
import { UserSessionContext } from '../../contexts/userSessionContext';
import SubNav from '../SubNav/SubNav';
import DTEIcon from '../DTEIcon/DTEIcon';
import LogInContents from '../common/LogInContents/LogInContents';
import ButtonPrimary from '../common/ButtonPrimary/ButtonPrimary';
import FormPageHeader from "./FormPageHeader/FormPageHeader"
import "./NavBar.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';

// @TODO: Add scrolling state and functionality per comp
// @TODO: Add fixed styling to header at top of window and add page content spacing to compensate
// @TODO: Add dynamic single source nav links object that can be passed to SubNav

const NavBar = (props:RouteComponentProps) => {
    const { userSession, setUserSession }     = useContext(UserSessionContext);
    const [hamburgerOpen, setHamburgerOpen]   = useState(false);
    const [logInDisplayed, setLogInDisplayed] = useState(false);
    const [isGlobalNav, setIsGlobalNav] = useState(true);

    const headerLoginState = logInDisplayed ? 'open' : 'closed';
    const navRef           = React.createRef<HTMLHtmlElement>();
    const siteTitle        = "Construction Management Center";
    const page = props.location.pathname as string;


    useEffect(() => {
        setIsGlobalNav(
            page !== "/change-password" &&
            page !== "/reset-password" &&
            page !== "/start-new-service"
            )
    }, [page]);

    useEffect((): void => {
        setLogInDisplayed(false);
    }, [userSession]);

    const handleDisplayToggle = () => {
        setHamburgerOpen(false);
        setLogInDisplayed(!logInDisplayed);

        // let screenWidth = window.matchMedia("(max-width: 1024px)");
        // if(screenWidth.matches || !userSession){
        //     setLogInDisplayed(!logInDisplayed);
        // }
    };
    const handleSignOutClick = () => {
        setUserSession(null);
        setLogInDisplayed(false);
    };
    const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
        if (
            navRef.current && !navRef.current.contains(
                (event.relatedTarget as Node) || document.activeElement
            )
        ) {
            setLogInDisplayed(false);
        }
    };
    const closeHamburgerMenu = () => {
        document.body.style.overflowY = "auto";
        setHamburgerOpen(false);
    };
    const openHamburgerMenu = () => {
        document.body.style.overflowY = "hidden";
        setLogInDisplayed(false);
        setHamburgerOpen(true);
    };
    const toggleHamburgerMenu = () => {
        const newHamburgerOpenValue = !hamburgerOpen;

        if (newHamburgerOpenValue) {
            openHamburgerMenu();
        } else {
            closeHamburgerMenu();
        }
    };

    const disableUntilAllNonsenseLoaded = (e: React.SyntheticEvent) => {
        // if (sessionStorage.getItem('dataLoading')) {
        //     e.preventDefault()
        // } else {
            closeHamburgerMenu()
        // }
    }

    const renderSignInBox = () => {
        return (userSession ?
        <div className="account-dropdown-signout">
                <div className="auth-controls-signout">
                    <NavLink to="/" tabIndex={-1}>
                        <button
                            onClick               = {handleSignOutClick}
                            type                  = "button"
                            data-track            = "SIGNOUT"
                            data-track-detail     = "CMC Home Page Header"
                            data-track-action     = "Sign Out"
                            data-track-sub-action = "Customer SignOut"
                            >
                            Sign Out
                        </button>
                    </NavLink>
                </div>
        </div>
         :
         <div className="account-dropdown" tabIndex={0}>
                <div className="sign-in-wrapper">
                    <LogInContents dataTrackDetail="CMC Home Page Header" />
                </div>
        </div>
        )
    };

    return(
        <>
        {isGlobalNav ?
            <header className={`site-header-wrapper${hamburgerOpen ? ' mobile-nav-open' : ' mobile-nav-closed'}`} onBlur={handleBlur} ref={navRef}>
                <div className="main-header-wrapper">
                    <div className="branding-wrapper">
                        <div className="logo-wrapper">
                            <NavLink
                                // onClick         = {closeHamburgerMenu}
                                to              = "/"
                                activeClassName = "logo tab-to-trigger"
                                exact
                            >
                            <DTEIcon />
                            </NavLink>
                        </div>
                        <div className="vertical-line"/>
                        <div className="site-title-wrapper desktop">
                            {/* <NavLink
                                to              = "/"
                                activeClassName = "site-title"
                                exact
                            > */}
                            <span className="site-title">{siteTitle}</span>
                            {/* </NavLink> */}
                        </div>
                    </div>
                    <div className="controls-wrapper">

                        <div className="main-nav-wrapper">
                            {process.env.REACT_APP_ENV === "azure" ? <></> : 
                            <div className={`account-wrapper ${headerLoginState}${userSession ? ' authed' : ' unauthed'}`}>
                                <button
                                    onClick   = {handleDisplayToggle}
                                    className = "nav-link account-btn"
                                >
                                    {userSession ? `Hi, ${userSession.user.displayName}` : `Sign In`}
                                    <FontAwesomeIcon
                                        className="chevron-icon"
                                        icon={faChevronDown}
                                    />
                                </button>
                                {renderSignInBox()}
                            </div>}
                            <nav>
                            <ul>
                                <li>
                                    <NavLink
                                        to              = "/contact-us"
                                        className="nav-link tab-to-trigger"
                                        activeClassName = "nav-link"
                                        exact
                                        tabIndex={0}
                                    >
                                        <span>Contact Us</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <a
                                        href      = {`${getDomain()}wps/wcm/connect/dte-web/home`}
                                        className="nav-link tab-to-trigger"
                                        tabIndex={0}
                                    >
                                        <span>Go to DTE Energy</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        </div>
                        <div className="menu-control-wrapper">
                        <HamburgerSlider
                            type        = "button"
                            onClick     = {toggleHamburgerMenu}
                            isActive    = {hamburgerOpen}
                            buttonWidth = {27}
                            barColor    = "white"
                        />
                        </div>

                    </div>
                </div>
                <div className="sub-header-wrapper">
                    <SubNav closeHandler={disableUntilAllNonsenseLoaded} />
                </div>
            </header>
            :
            <FormPageHeader siteTitle={siteTitle} page={page} />
        }
        </>
    );
};

export default withRouter(NavBar);