import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { LogLevel, Logger } from "msal";

const logger = new Logger(
  (logLevel, message, containsPii) => {
    console.log("[MSAL]", message);
  },
  {
    level: LogLevel.Verbose,
    piiLoggingEnabled: false
  }
);

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  return msie || msie11;
};

const envClientId = () => {
  if (window.location.hostname.includes('nonprod')) {
    return "32a59ef3-6b4b-4ae4-8f1b-1315f78abc0b"
  }
  if (window.location.hostname === "localhost") {
    return "32a59ef3-6b4b-4ae4-8f1b-1315f78abc0b"
  }
  return "5ba800e3-d21a-4efc-924b-ce262dbc51b2"
}

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
export const authProvider = new MsalAuthProvider(
  {
    auth: {
      clientId: envClientId(),
      authority: "https://login.microsoftonline.com/8e61d5fe-7749-4e76-88ee-6d8799ae8143",
        validateAuthority: true,
        postLogoutRedirectUri: window.location.hostname === "localhost" ? "http://localhost:3000/employees" : "https://nonprod.cmc.customer.sites.dteenergy.com/",
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: isIE()
    },
    // Enable logging of MSAL events for easier troubleshooting.
    // This should be disabled in production builds.
    system: {
      logger: logger as any
    }
  },
  {
    scopes: ["openid"]
  },
  {
    loginType: LoginType.Redirect,
    // When a token is refreshed it will be done by loading a page in an iframe.
    // Rather than reloading the same page, we can point to an empty html file which will prevent
    // site resources from being loaded twice.
    // tokenRefreshUri: window.location.origin + "/auth.html"
  }
);
