import React, {
  useState,
  useContext,
  useEffect,
} from "react";
import { AccessLevel } from "../../../types/accessLevel";
import { ProjectListItemProps } from "../../../types/ProjectListItem";
import { ModalContext } from "../../../contexts/modalContext";
import ButtonPrimary from "../../common/ButtonPrimary/ButtonPrimary";
import ShowMoreProjectsList from "../ShowMoreProjectsList/ShowMoreProjectsList";
import "./AddNewContactsModalContent.scss";


interface AddNewContactsModalContentProps {
  projects: any;
  addToProject: (projects: any) => void;
}

const AddNewContactsModalContent = (props: AddNewContactsModalContentProps) => {
  const { setModalOpen } = useContext(ModalContext);

  const [atLeastOneProjectSelected, setAtLeastOneProjectSelected] = useState(false);
  const [projectList, setProjectList] = useState<ProjectListItemProps[]>([]);
  const [atLeastOneChangeMade, setAtLeastOneChangeMade] = useState(false);
  const [selectAllSelected, setSelectAllSelected] = useState(false);
  const [selectAllValue, setSeletAllValue] = useState<AccessLevel>("View");
  
  useEffect(() =>  {
    setProjectList(props.projects)
  }, [props.projects])

  const setAllItemsSelectedTo = (selected: boolean) => {
    const newData = projectList.map(item => {
      const newItem = { ...item };
      newItem.selected = selected;

      return newItem;
    });

    setAtLeastOneProjectSelected(selected);
    setProjectList(newData);
  };

  const clearSelectedItems = () => {
    setAllItemsSelectedTo(false);
  };

  const handleSelectAll = () => {
    setSelectAllSelected(!selectAllSelected)
    const newData = projectList.map(item => {
      const newItem = { ...item };
      newItem.selected = !selectAllSelected;

      return newItem;
    });
    setProjectList(newData)
    setAtLeastOneProjectSelected(!selectAllSelected)
  };

  const handleCheckboxToggle = (id: string) => {

    const newData = projectList.map(item => {
      if (item.premiseID === id) {
        const newItem = { ...item };
        newItem.selected = !newItem.selected;
        return newItem;
      }
      return item;
    });

    const selectedItems = newData.filter(item => {
      return item.selected;
    });

    setAtLeastOneProjectSelected(selectedItems.length > 0);

    setProjectList(newData);

  };

  const workOrderSelectChangeHandler = (
    id: string,
    accessLevel: AccessLevel
  ) => {
    const newData = projectList.map(item => {
      if (item.premiseID === id) {
        const newItem = { ...item };
        newItem.userRole = accessLevel;
        return newItem;
      }
      return item;
    });
    setProjectList(newData);
  };

  const handleSetAllRoles = (accessLevel: AccessLevel) => {
    const newData = projectList.map(item => {
      const newItem = { ...item };
      if (newItem.selected) {
        newItem.userRole = accessLevel;
      }
      return newItem;
    });

    setProjectList(newData);
    setSeletAllValue(accessLevel);
  };

  const handleAdd = () => {
    let selectedProjects = projectList.filter(p => {
      return p.selected;
    })

    let unselectedProjects = projectList.filter(p => !p.selected)

    let sanitizedProjects = selectedProjects.map((p: any) => {
      return {
        premiseID: p.premiseID,
        userRole: p.userRole,
        projectAddress: p.projectAddress,
        operation: "A"
      }
    });

    props.addToProject && props.addToProject(sanitizedProjects);
    setModalOpen(false);
    setProjectList(unselectedProjects)
  }

  const showMoreList = (
   <ShowMoreProjectsList 
      projectList={projectList}
      clearSelectedItems={clearSelectedItems}
      handleCheckboxToggle={handleCheckboxToggle}
      workOrderSelectChangeHandler={workOrderSelectChangeHandler}
      selectAllSelected={selectAllSelected}
      handleSelectAll={handleSelectAll}
      selectAllValue={selectAllValue}
      handleSetAllRoles={handleSetAllRoles}
    /> 
  )
  

  const modalButtons = (
    <div className="add-new-contact-modal__button-container">
      <ButtonPrimary
        className="add-new-contact-modal__button-container-save-btn"
        onClick={() => {
          setAtLeastOneProjectSelected(false);
          setAtLeastOneChangeMade(false);
          handleAdd();
        }}
        disabled={!atLeastOneProjectSelected && !atLeastOneChangeMade}
        dataTrack="CMC"
        dataTrackDetail="Invite Contact"
        dataTrackAction="click"
        dataTrackSubAction="submit"
      >
        &nbsp;Save
      </ButtonPrimary>
      <ButtonPrimary
        className="add-new-contact-modal__button-container-cancel-btn"
        onClick={() => {
          setModalOpen(false);
        }}
      >
        Cancel
      </ButtonPrimary>
    </div>
  );

  return (
    <section className="add-new-contact-modal">
      <div className="add-new-contact-modal__contents">
      {showMoreList}
      <br />
      {modalButtons}
    </div>
    </section>
  );
};

export default AddNewContactsModalContent;
