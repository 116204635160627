import React, { useState, useEffect } from 'react';
import Task from './Task';
import moment from 'moment';
import business from 'moment-business';
import Calendar from '../../Widgets/Calendar';
import { serviceIdToSlug } from '../../../utilities/utilities';
import getDomain from "../../../services/getDomain"
import axios from 'axios'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cacheHandler } from "../../../utilities/cacheUtils";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

// step 3 date range - no slot - no adding time
// step 7 single date - no time
// step 8 single date - any time slot


// date: "2020-02-23"
// endTime: "12-00-00" || endTime: "16-29-00"
// slotLength: "04-00"
// startTime: "08-00-00" || "12-00-00"
// timeSlot: "AM"
// vendorName: "DTE Gas"

// date: "2020-01-20T18:13:45.650Z"
// endTime: "12:00"
// installation: 2910804109
// premiseID: 4194388154
// startTime: "8:00"
// zipExt: 1704
interface DateAvailableProps{
    date: Date,
    endTime: string,
    slotLength: string,
    startTime: string,
    timeSlot: string,
    vendorName: string
};

const MeetingTask: React.FC<{
        date?            : any;
        dates?           : Array<DateAvailableProps>;
        startDate?       : any;
        endDate?         : any;
        slot?            : string;
        status?          : string;
        label?           : string;
        type             : "site_meeting" | "meter_install" | "construction_schedule";
        service          : string;
        installationNum? : string;
        SOdetails?: any;

    }> = ({
        date,
        dates,
        startDate,
        endDate,
        slot,
        status,
        label,
        type,
        service,
        installationNum,
        SOdetails
    }): React.ReactElement => {{
    // console.log("dates", dates)
    // if(dates && dates.length) {
    //     startDate = dates[0].date;
    //     endDate = dates[dates.length - 1].date;
    // }

    const [meterSetDates, setMeterSetDates] = useState([])
    let isMeterEligible = false

    const axiosDatesRequest = async (data:any): Promise<void> => {
        console.log('calling')
      const parser    = require('parse-address');
      const parsed    = parser.parseLocation(data.projectAddress);
      // debugger;
      const datesPath =  `${getDomain()}api/construction/usersManagement/v1/users/appointments/premiseID/
      ${data.premiseID}
      /orderType/${data.meterInstallation.orderType}/executionCode1/${data.meterInstallation.executionCode}?
      zipCode=${parsed.zip}
      &postalCity=${parsed.city}
      &zipExtension=${parsed.plus4 || ""}
      &installation=${data.installationNumber}`.replace(/\s/g, '')

      await axios.get(
       datesPath,
        {withCredentials: true}
        ).then((r:any) => {
          console.log('resp')
          console.log(r.data)
          setMeterSetDates(r.data.appointments)
      }).catch((e:any) => {console.error(e)})
    }

    if (
        type==="meter_install" &&
        SOdetails &&
        SOdetails.installationEligible &&
        SOdetails.installationEligible === 'true' &&
        meterSetDates.length < 1 &&
        (!date || date === "0000-00-00") &&
        cacheHandler.get("ElevatedRole") !== "View"
    ) {
        isMeterEligible = true
        axiosDatesRequest(SOdetails)
    } else if (meterSetDates.length) {
        isMeterEligible = true
    }



    service = serviceIdToSlug(service)
    status = status ? status.replace(/ /g, '_').toLowerCase() : 'not_started'
    // console.log('status mutated', status)

    const dayRange = 7;
    if (startDate && !endDate){
        endDate = business.addWeekDays(moment(startDate), dayRange).format();
    }
    if (!startDate && endDate){
        startDate = business.subtractWeekDays(moment(endDate), dayRange).format();
    };

    const getSlot = () => {
        switch(slot){
            case "AM":
                return "AM (8:00 AM – 12:00 PM)";
            case "PM":
                return "PM (12:00 PM – 4:30 PM)";
            case "All Day":
                return "Any Time (8:00 AM – 4:30 PM)";
            default:
                return slot;
        };
    };

    const content: any = {
        site_meeting: {
            label: "Appointment",
            bodyText: {
                gas: "During your appointment, the DTE representative will evaluate the location of existing utilities, clarify your energy needs and review service options. Based on service requirements, you will discuss a realistic completion date and estimated cost of installing service. Please provide a clear path to all gas appliances, the new gas service line and your preferred meter location",
                electric: "Your DTE Planner will visit your site to evaluate all electric service needs. The planner will review your service options, the cost of installing service and discuss a realistic completion date."
            }
        },
        meter_install: {
            label: "Meter Installation",
            bodyText: {
                gas: !meterSetDates.length ? "Please call 800.388.0178 to schedule your meter installation." : "You will need to select a preferred date/time for your meter installation. One of our crews will install your meter and make all connections to start your natural gas service. Please select a date/time for your meter installation. ",
                electric: "Our crew will install your meter and make all connections to the power source. Please ensure the installation path and area surrounding your meter are clear of all obstacles and debris."
            }
        },
        construction_schedule: {
            label: "Construction",
            bodyText: {
                gas: "Your construction schedule will be developed in order to achieve your project’s agreed-upon completion date.",
                electric: "Your construction schedule will be developed in order to achieve your project’s agreed-upon completion date."
            },
            notes: "Note: Completion dates are approximate and may be impacted by environmental or weather conditions. Please maintain site readiness for the entire scheduled window."
        },
    }

    const defaultSentenceStart = `Your ${content[type].label.toLowerCase()} has`;
    const statuses: any = {
        not_started: {
            color: "grey",
            icon: "future",
            altText: "Future",
            message: `${defaultSentenceStart} not been scheduled.`
        },
        in_progress: {
            color: "default",
            icon: "in_progress",
            altText: "To-Do",
            message: `${defaultSentenceStart} been scheduled.`
        },
        canceled: {
            color: "red",
            icon: "canceled",
            altText: "Not Approved",
            message: `${defaultSentenceStart} been canceled.`
        },
        completed: {
            color: "green",
            icon: "completed",
            altText: "Completed",
            message: `Your ${content[type].label.toLowerCase()} is complete.`
        },
        on_hold: {
            color: "red",
            icon: "emergency",
            altText: "On Hold",
            message: `${defaultSentenceStart} been placed on hold.`
        }
    }

    const textLoad = () => {
        if(type === "meter_install") {
            if(status === "completed") {
                return false
            } else {
                return true
            }
        } else {
            return true;
        }
    }

    return(
        <Task
            className   = "meeting-task"
            statusColor = {statuses[status].color}
            status      = {status}
            label       = {label || content[type].label}
            icon        = {statuses[status].icon}
            iconAltText = {statuses[status].altText || ""}
        >
            
            {
                textLoad() ? <p>{content[type].bodyText[service]}</p> :
                null
            }

            { content[type].notes ? <><br/>{content[type].notes}</> : null}
            <h5 className="message">{statuses[status].message}</h5>

            { date && status !== "not_started" && installationNum === undefined &&
                <Calendar
                    startDate       = {moment(date).format()}
                    slot          = {getSlot()}
                />
            }

            { startDate && status !== "not_started" && installationNum === undefined &&
                <Calendar
                    startDate       = {moment(startDate).format()}
                    endDate         = {moment(endDate).format()}
                    slot          = {getSlot()}
                />
            }

            {meterSetDates.length ?
                <Calendar
                    startDate       = {moment(startDate).format()}
                    endDate         = {moment(endDate).format()}
                    dates           = {meterSetDates}
                    inputCalendar   = {true}
                    inputTime       = {true}
                    installationNum = {SOdetails.meterInstallation.installationServiceOrder}
                    SOdetails       = {SOdetails}
                    // slot          = {getSlot()}
                />
            : isMeterEligible ? <FontAwesomeIcon style={{}} icon={faSpinner} spin /> : null}

        </Task>
    );
}};

export default MeetingTask;
