import React, { useState, useEffect, createContext } from 'react';

interface ModalState {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalToBeScrolled: boolean;
  setModalToBeScrolled: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalContext = createContext<ModalState>({
  modalOpen: false,
  setModalOpen: () => {},
  modalToBeScrolled: false,
  setModalToBeScrolled: () => {}
});

const ModalContextProvider: React.FC = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalToBeScrolled, setModalToBeScrolled] = useState(false);

  function applyModalStyle(isModalOpen:boolean) {
    if (isModalOpen) {
      document.body.style.overflowY = 'hidden';
      if (window.screen.width <= 767) {
        const scrollY = window.pageYOffset;
        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollY}px`;
      }
    } else {
      document.body.style.overflowY = 'auto';
      if (window.screen.width <= 767) {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    }
  }

  useEffect(() => {
    applyModalStyle(modalOpen)
  }, [modalOpen]);

  window.onpopstate = () => {
    applyModalStyle(false)
  };

  return (
    <ModalContext.Provider
      value={{
        modalOpen,
        setModalOpen,
        modalToBeScrolled,
        setModalToBeScrolled
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalContextProvider };
