import React from "react";
import checkMarkIcon from "../../../assets/checkmark.svg";
import minusIcon from "../../../assets/minus.svg";
import "./AccessLevelChart.scss";

const AccessLevelChart: React.FunctionComponent = (): React.ReactElement => {
  const checkMark = <img src={checkMarkIcon} alt="check mark" />;
  const minus = <img src={minusIcon} alt="check mark" />;

  return (
    <>
      <table className="access-level-chart">
        <thead>
          <tr>
            <th />
            <th>Administrator</th>
            <th>View, Edit &amp; Invite</th>
            <th>View &amp; Edit</th>
            <th>View Only</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>View Project Status</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
          </tr>
          <tr>
            <td>Invite New Contacts</td>
            <td>{checkMark}</td>
            <td>{checkMark}</td>
            <td>{minus}</td>
            <td>{minus}</td>
          </tr>
          <tr>
            <td>View Contacts</td>
            <td>{checkMark}</td>
            <td>{minus}</td>
            <td>{minus}</td>
            <td>{minus}</td>
          </tr>
        </tbody>
      </table>

      <div className="access-level-chart-mobile">
        <table>
          <thead>
            <tr>
              <th>Administrator</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>View Project Status</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Invite Contacts</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>View Contacts</td>
              <td>{checkMark}</td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>View, Edit &amp; Invite</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>View Project Status</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Invite Contacts</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Invite Contacts</td>
              <td>{minus}</td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>View &amp; Edit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>View Project Status</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Invite Contacts</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Invite Contacts</td>
              <td>{minus}</td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>View Only</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>View Project Status</td>
              <td>{checkMark}</td>
            </tr>
            <tr>
              <td>Invite Contacts</td>
              <td>{minus}</td>
            </tr>
            <tr>
              <td>Invite Contacts</td>
              <td>{minus}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AccessLevelChart;
