import React from 'react';
import NumberFormat from 'react-number-format';
import Task from './Task';

const AssignmentTask: React.FC<{
    status?: "Assigned" | "Not Assigned";
    service: "2280" | "2199";
    step: string;
    name?  : string;
    email? : string;
    phone? : string;
}> = ({
    status,
    service,
    step,
    name,
    email,
    phone
}): any => {{
    
    const statusKey = status && status.replace(' ', '_').toLowerCase() || "not_assigned"
    const statuses: any = {
        not_assigned: {
            color: "grey",
            icon: "future",
            altText: "Future"
        },
        assigned: {
            color: "green",
            icon: "completed",
            altText: "Complete"
        }
    }
    const bodyCopy: any = {
        2280: {
            B00: {
                label: "DTE representative",
                copy: null
            },
            C00: {
                label: "DTE representative",
                copy: "Your DTE representative will schedule a meeting to discuss the installation requirements of your gas service."
            },
            G00: {
                label: "DTE representative",
                copy: "Your DTE representative will coordinate the construction schedule with all crews and work with you through the completion of your project."
            }
        },
        2199: {
            B00: {
                label: "DTE Support Specialist",
                copy: null
            },
            C00: {
                label: "DTE Planner",
                copy: "Your project will be assigned a DTE Planner who will oversee your project from design to scheduling and construction. The DTE Planner will schedule a meeting to discuss the installation requirements of your electric service."
            },
            G00: {
                label: "DTE Case Manager",
                copy: "Your DTE Case Manager will be your primary contact for any issues or concerns related to your project."
            }
        }
    }

    const label = bodyCopy[service][step].label;
    const assignmentTitle   = statusKey === 'assigned' ? "Your project has been assigned to:":`Your project has not been assigned to a ${label}.`;
    return(
        <Task
            className   = "assignment-task"
            statusColor = {statuses[statusKey].color}
            status      = {statusKey}
            icon        = {statuses[statusKey].icon}
            iconAltText = {statuses[statusKey].altText}
            label       = {label}
        >
            <p>{bodyCopy[service][step].copy}</p>
            <h5 className="message">{assignmentTitle}</h5>
            {statusKey === 'assigned' &&
                <address className="contact-block">
                    <div className="block-line">
                        <div className="block-value">
                            {name}
                        </div>
                    </div>
                    <div className="block-line">
                        <div className="block-value">
                            {label}
                        </div>
                    </div>
                    {phone && (
                        <div className="block-line">
                            <div className="block-value">
                                <NumberFormat
                                    name        = "phoneValue"
                                    type        = "tel"
                                    format      = "(###) ###-####"
                                    placeholder = "(   )   -"
                                    value       = {phone}
                                    displayType = "text"
                                />
                            </div>
                        </div>
                    )}
                    {email && (
                        <div className="block-line">
                            <div className="block-value">
                                <a href={`mailto:${email.toLowerCase() || " "}`}>{email.toLowerCase() || " "}</a>
                            </div>
                        </div>
                    )}
                </address>
            }
        </Task>
    );
}};

export default AssignmentTask;
