import React from 'react';
import Task from './Task';
import { formatAddress } from '../../../utilities/utilities';
import NumberFormat from 'react-number-format';

// @TODO: Gas or Electric?

const SummaryTask: React.FC<{
        data  : any;
        label?: string;
    }> = ({
        data,
        label = "Request Summary"
    }): any => {{
    const statusKey = "completed"
    const icons = {
        completed: {
            name: "completed",
            altText: "Complete"
        }
    }
    const statuses: any = {
        completed: {
            color: "green",
            icon: icons.completed
        }
    }
    return(<Task
            className   = "summary-task"
            status      = {statusKey}
            statusColor = {statuses[statusKey].color}
            icon        = {statuses[statusKey].icon.name}
            iconAltText = {statuses[statusKey].icon.altText}
            label       = {label}
        >
            <div className="info-block">
                <p>Your request has been received.</p>
            </div>
            <h4>Project Information</h4>
            <div className="info-block">
                <div className="block-line">
                    <div className="block-label">
                        Job Type:
                    </div>
                    <div className="block-value">
                        {data.jobType}
                    </div>
                </div>
                <div className="block-line">
                    <div className="block-label">
                        Date Ready:
                    </div>
                    <div className="block-value">

                    </div>
                </div>
                <div className="block-line">
                    <div className="block-label">
                        Development Type:
                    </div>
                    <div className="block-value">
                        {data.developmentType}
                    </div>
                </div>
                <div className="block-line">
                    <div className="block-label">
                        Service Address:
                    </div>
                    <div className="block-value">
                        <address>
                            {formatAddress(data.projectAddress)}
                        </address>
                    </div>
                </div>

            </div>
            <h4>Service Information</h4>
            <div className="info-block">
                <div className="block-line">
                    <div className="block-label">
                        Service Needed:
                    </div>
                    <div className="block-value">
                        {data.serviceNeeded}
                    </div>
                </div>
                <div className="block-line">
                    <div className="block-label">
                        Requested Date of Service:
                    </div>
                    <div className="block-value">
                        {/* {getFormattedDateTime(data.date_requested)} */}
                    </div>
                </div>
            </div>
            <h4>Contact Information</h4>
            <div className="info-block">
                <h5>Requestor</h5>
                <div className="block-line">
                    <div className="block-label">
                        Name:
                    </div>
                    <div className="block-value">
                        {data.projectInitiator ? data.projectInitiator.name : ""}
                    </div>
                </div>
                <div className="block-line">
                    <div className="block-label">
                        Phone Number:
                    </div>
                    <div className="block-value">
                        {data.projectInitiator ? data.projectInitiator.phone : ""}
                    </div>
                </div>
            </div>
            <div className="info-block">
                <h5>Customer Information</h5>
                <div className="block-line">
                    <div className="block-label">
                        Name:
                    </div>
                    <div className="block-value">
                        {data.contacts ? data.contacts.customer.name : ""}
                    </div>
                </div>
                <div className="block-line">
                    <div className="block-label">
                        Company:
                    </div>
                    <div className="block-value">
                        {data.contacts ? data.contacts.customer.company : ""}
                    </div>
                </div>
                <div className="block-line">
                    <div className="block-label">
                        Account No.
                    </div>
                    <div className="block-value">
                        {data.contacts ? data.contacts.customer.accountNumber : ""}
                    </div>
                </div>
            </div>
            <div className="info-block">
                <h5>Primary Site Information</h5>
                <div className="block-line">
                    <div className="block-label">
                        Name:
                    </div>
                    <div className="block-value">
                        {/* {data.summary.contacts.site.name} */}
                    </div>
                </div>
                <div className="block-line">
                    <div className="block-label">
                        Company:
                    </div>
                    <div className="block-value">
                        {/* {data.summary.contacts.site.company} */}
                    </div>
                </div>
            </div>
            <div className="info-block">
                <h5>Billing Address</h5>
                <div className="block-line">
                    <div className="block-label">
                        Name:
                    </div>
                    <div className="block-value">
                        {data.contacts ? data.contacts.billing.name : ""}
                    </div>
                </div>
                <div className="block-line">
                    <div className="block-label">
                        Company:
                    </div>
                    <div className="block-value">
                        {data.contacts ? data.contacts.billing.company : ""}
                    </div>
                </div>
                <div className="block-line">
                    <div className="block-label">
                        Email:
                    </div>
                    <div className="block-value">
                        {data.contacts ? data.contacts.billing.email : ""}
                    </div>
                </div>
                <div className="block-line">
                    <div className="block-label">
                        Phone Number:
                    </div>
                    <div className="block-value">
                        <NumberFormat
                            name        = "phoneValue"
                            type        = "tel"
                            format      = "(###) ###-####"
                            placeholder = "(   )   -"
                            value       = {data.contacts ? data.contacts.billing.phone : ""}
                            displayType = "text"
                        />
                    </div>
                </div>
                <div className="block-line">
                    <div className="block-label">
                        Address:
                    </div>
                    <div className="block-value">
                        <address>
                            {data.contacts ? formatAddress(data.contacts.billing.address) : ""}
                        </address>
                    </div>
                </div>
            </div>
        </Task>
    );
}};

export default SummaryTask;
