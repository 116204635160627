import React, { useState, useEffect } from "react";
import chevronIconWhite from "../../../assets/chevron-up-white.svg";
import "./ScrollToTopButton.scss";

const ScrollToTopButton: React.FC = () => {

  const [buttonClass, setButtonClass] = useState("scroll-to-top");

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
  }, []);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setButtonClass("scroll-to-top show");
    } else {
      setButtonClass("scroll-to-top");
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    //behavior "smooth" is not supported by IE11 (go figure), so checking browser support and using a polyfill as a fallback may be an alternative worth exploring
  }


  return (
    <div onClick={() => scrollToTop()} className={buttonClass}>
      <img
        className="scroll-to-top__chevron"
        src={chevronIconWhite}
        alt="chevron"
      />
    </div>
  )
};

export default ScrollToTopButton;
