import React, { useState, useEffect } from "react";
import chevronIcon from "../../../assets/chevron-down-grey.svg";
import chevronIconWhite from "../../../assets/chevron-up-white.svg";
import "./Accordion.scss";
import LogInCard from "../../LandingPage/LogInCard/LogInCard";
interface AccordionProps {
  heading: JSX.Element | JSX.Element[];
  className?: string;
  icon?: JSX.Element;
  includeConnectingLine?: boolean;
  open?: boolean;
  includeChevron?: boolean;
  includeWhiteChevron?: boolean;
  tabIndex?: boolean;
}
const Accordion: React.FC<AccordionProps> = ({
  heading,
  className = "",
  icon,
  includeConnectingLine,
  open,
  includeChevron,
  includeWhiteChevron,
  children,
  tabIndex
}): React.ReactElement => {
  const contentsRef = React.createRef<HTMLDivElement>();
  const [accordionOpen, setAccordionOpen] = useState(!!open);
  // To smoothly animate the opening/closing of this modal, we need to set the maxHeight of the contents element
  // when it is rendered (this is because the height property cannot be animated from 0 to 100%, only fixed values
  // are animated)
  const setHeight = () => {
    if (contentsRef.current && accordionOpen) {
      observer.observe(contentsRef.current, config)
      contentsRef.current.style.maxHeight = `${
        contentsRef.current.scrollHeight
      }px`;

      if(contentsRef.current.parentElement &&
        contentsRef.current.parentElement.classList.contains("index8") &&
        contentsRef.current.scrollWidth  < 350) {

        contentsRef.current.style.maxWidth = `${contentsRef.current.scrollWidth - 63}px`;
      } else if(contentsRef.current.parentElement &&
        contentsRef.current.parentElement.classList.contains("index8") &&
        contentsRef.current.scrollWidth  > 350) {
        contentsRef.current.style.maxWidth = "1000px"
      }
      setTimeout(() => {
        let e = document.getElementsByClassName("contents-open") as HTMLCollection;
        for (let index = 0; index < e.length; index++) {
          const el = e[index] as any;
          if(el.style.overflow !== "visible") {
            el.style.overflow = "visible";
          }
        }
      }, 200);
    } else if (contentsRef.current && !accordionOpen) {
      contentsRef.current.style.maxHeight = "0px";
      contentsRef.current.style.maxWidth = "auto";
      contentsRef.current.style.overflow = "hidden";
    }
  }
  window.addEventListener('resize', setHeight );

  const config = { attributes: true, childList: true, subtree: true };

// Callback function to execute when mutations are observed
const heightCallback = function() {
    setHeight();
};

// Create an observer instance linked to the callback function
const observer = new MutationObserver(heightCallback);

  useEffect((): void => {
    setHeight();
  }, [accordionOpen, contentsRef]);

  const toggleAccordion = (): void => {
    setAccordionOpen(!accordionOpen);
  };
  let customClassName = `accordion ${className}`;
  if (accordionOpen) {
    customClassName = customClassName.concat(" open");
  }
  let connectingLineContent = null;
  if (includeConnectingLine && icon) {
    connectingLineContent = <div className="connecting-line" />;
  }
  let iconContent = null;
  if (icon) {
    iconContent = (
      <div
        className="icon-container"
        aria-hidden="true"
        onClick={toggleAccordion}
      >
        {!className.includes("index1") && <div className="icon-line"/>}
        {icon}
        {!className.includes("index8") && <div className="icon-line"/>}
      </div>
    );
  } else {
    customClassName = customClassName.concat(" no-icon");
  }
  function compileTest(){
    let elements = document.getElementsByTagName("contents-open");
    for(let element of elements as any){
      console.log(element);
      element.style.overflow = "visible";
    }
  }
  return (
    <div
      className={customClassName}
      tabIndex={tabIndex ? 0 : -1}
      onKeyUp={(e) => {if(e.keyCode === 13){toggleAccordion();}}}
    >
      {iconContent}
      {/* We disable the a11y eslint rules for the clickable header because it is readable by a screen reader as-is */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <div
        className="heading"
        onClick={toggleAccordion}
        role="button"
      >
        <div className="accordion_header_custom-content">{heading}</div>
        {(includeChevron || includeWhiteChevron) ? (
          <img
            className="accordion__header__chevron"
            src={includeChevron ? chevronIcon : chevronIconWhite}
            alt="chevron"
          />
        ) : null}
      </div>
      <div className="connecting-line-container">{connectingLineContent}</div>
      <div className={customClassName.indexOf("open") !== -1 ? "contents-open":"contents"} ref={contentsRef}>
        {children}
      </div>
    </div>
  );
};
export default Accordion;