import React from "react";
import Tippy, {TippySingleton} from '@tippy.js/react';
import {
  getInProgressStep,
  getFormattedDateTime,
  formatAddress,
  getSODetailsFromWON,
  isNotGuestSearch
} from "../../../utilities/utilities";
import { WorkOrder } from "../../../types/workOrder";
import { Step } from "../../../types/getWorkOrderResponse";
import chevronIcon from "../../../assets/bracket-right.svg";
import Card from "../Card/Card";
import Pill from "../Pill/Pill";
import SVGIcon from "../SVGIcon/SVGIcon";
import "./WorkOrderOverview.scss";

const WorkOrderOverview: React.FC<{
  address?               : string;
  workOrder              : WorkOrder;
  woWithRelated?          : boolean
  linked?                : boolean;
  relatedWO?             : boolean;
}> = ({
  address,
  workOrder,
  woWithRelated,
  linked,
  relatedWO
}): React.ReactElement => {

  if (address === "show") {
    address = workOrder.address
  }

  // THIS IS TEMP CODE TO TRANSFORM OLD PROPERTIES FROM THE TAPE SOLUTION INTO THE UPDATED PROPERTIES
  // @TODO: Remove this once the tape solution is using the updated property names
  if (workOrder.PWO) {
    // eslint-disable-next-line no-param-reassign
    workOrder.num = workOrder.PWO;
    // workOrder.serviceType = workOrder.service_type_label as (
    //   | "electric"
    //   | "gas")
  }

  if (isNotGuestSearch()) {
    const summary = getSODetailsFromWON(workOrder.PWO);
    if (
        summary
        && summary.serviceType === "Gas"
        && summary.meterInstallation
    ) {
      let newStatus = summary.projectStatus
      if (
          summary.meterInstallationData
          && summary.meterInstallationData[0]
          && summary.meterInstallationData[0].meterInstallation
      ) {
        newStatus = summary.meterInstallationData[0].meterInstallation.status
      } else {
        // G_1-7
        newStatus = "Not Started"
      }
      // if (summary.gateCode === "G_1-7") {
      //   newStatus = "Not Started"
      // }

      workOrder.steps = workOrder.steps.map(step => {
        if (step.step_id === "H00") {
          if (newStatus === "TODO") {
            newStatus = "Not Started";
          }
          step.step_status = newStatus;
        }
        return step;
      })
    }
  }

  const inProgressStepNum = getInProgressStep(workOrder.steps);

  const renderTooltipBody = (step: Step) => {
    return (
      <>
        <div className="label-wrapper">
          <strong>{step.display_order} of {workOrder.steps.length}:</strong> {step.step_label}
        </div>
        <div className="status-wrapper">
          ({step.step_status})
        </div>
      </>
    );
  };

  // Returns the most recent "last updated" value from the steps
  const getLatestLastUpdated = () => {
    return Math.max(
      ...workOrder.steps.map((step: { last_updated: string }) =>
        new Date(step.last_updated.replace(/\/19$/, '/2019') || "1/1/2019").getTime()
      )
    );
  };

  // Build SVG icon row
  const stepStatuses = workOrder.steps.map(
    (step: Step, i: number): React.ReactElement => (
      <Tippy content={renderTooltipBody(step as Step)} key={step.step_id}>
        <div className="step-icon">
          {i > 0 && <div className="liner" />}
          <div className="icon-wrapper">
            <SVGIcon
              name={step.step_status ? step.step_status : 'Not Required'}
              width="15px"
              height="22px"
            />
          </div>
          {i < 7 && <div className="liner" />}
        </div>
      </Tippy>
    )
  );

  const progressBar = (
    <>
      <div className="progress-bar">
        <TippySingleton updateDuration={500} className="dte-theme">
          {stepStatuses}
        </TippySingleton>
      </div>
      <span>
        <strong>
          Step {inProgressStepNum} of {workOrder.steps.length}:{" "}
        </strong>{" "}
        {workOrder.steps[inProgressStepNum - 1].step_label}
      </span>
    </>
  );

  return (
    <Card className={`work-orders-wrapper${ woWithRelated ? " wo-with-related" : ""} ${relatedWO ? " related-wo" : ""}`}>
      {address ? <h1 className="work-order-address">{formatAddress(address)}</h1> : null}
      <div className="work-order-overview-wrapper">
        <div className="overview-block order-number table-wo-col">
          <div className="block-label">{relatedWO && "Related " }Work Order</div>
          <div className="block-value">
          {
            relatedWO ? <a href={`/work-order/${workOrder.num}`}>{workOrder.num}</a> : <p>{workOrder.num}</p>
          }
          </div>
        </div>
        <div className="overview-block service-type table-type-col">
          <div className="block-label">Service Type</div>
          <div className="block-value pills-wrapper">
            <Pill>{workOrder.service_type_label}</Pill>
          </div>
        </div>
        <div className="overview-block status table-status-col">
          <div className="block-label">Status</div>
          <div className="block-value progress-bar-wrapper">
            {progressBar}
          </div>
        </div>
        <div className="overview-block last-updated table-date-col">
          <div className="block-label">Last Updated</div>
          <div className="block-value">
            {getFormattedDateTime(getLatestLastUpdated())}
          </div>
        </div>
        {linked && <div className="chevron-wrapper">
          <img src={chevronIcon} alt="chevron" />
      </div>}
      </div>
    </Card>
  );
};

export default WorkOrderOverview;
