import React from 'react';
import { StaticText } from './StaticText';
import { StaticTaskContent } from "./StaticTaskContent"
import { Step } from '../../../types/getWorkOrderResponse';
import {
  getInProgressStep,
  getSODetailsFromWON,
  isNotGuestSearch,
  safelyDisplayMMDDYY,
} from '../../../utilities/utilities';
import Card from '../../common/Card/Card';
import PaymentTask from '../../common/Tasks/PaymentTask';
import SummaryTask from '../../common/Tasks/SummaryTask';
import MeetingTask from '../../common/Tasks/MeetingTask';
import ApprovalTask from '../../common/Tasks/ApprovalTask';
import DocumentTask from '../../common/Tasks/DocumentTask';
import AgreementTask from '../../common/Tasks/AgreementTask';
import AssignmentTask from '../../common/Tasks/AssignmentTask';
import DeliverableTask from '../../common/Tasks/DeliverableTask';
import InstallationTask from '../../common/Tasks/InstallationTask';
import StepAccordion from '../StepAccordion/StepAccordion';
import CommonApprovalTimelinesModalLink from '../CommonApprovalTimelinesModalLink/CommonApprovalTimelinesModalLink';
import ErrorBoundary from '../../common/ErrorBoundary/ErrorBoundary'
import './WorkOrderSteps.scss';

// TODO: fix this 'any' typing
const WorkOrderSteps: React.FC<{ data: any }> = ({
  data
}): React.ReactElement => {
  const inProgressStepNum = getInProgressStep(data.steps);
  const serviceType = data.service_type_label.includes('Gas') ? 'gas' : 'electric'
  const isGas = serviceType === 'gas'
  const isElectric = serviceType === 'electric'
  const staticDataSet = StaticText[serviceType]

  let SOdetails: any = [];
  let miData: any = {
    isValid: false,
    realDate: '',
    realSlot: '',
    displayStatus: ''
  };
  let temp:any
  
  const newInstallationBlock = data.steps[7].installation
  console.log('installation', newInstallationBlock)

  if (isNotGuestSearch()) {


    SOdetails = getSODetailsFromWON(data.PWO);
    if(data.steps[0].projectDetails.jobType) {
      SOdetails.jobType = data.steps[0].projectDetails.jobType
    }
    console.log('soDetails', SOdetails)
    if (
      SOdetails.hasOwnProperty('meterInstallationData') &&
      SOdetails.serviceType === 'Gas' &&
      SOdetails.meterInstallationData[0] &&
      SOdetails.meterInstallationData[0].meterInstallation
    ) {
      temp = SOdetails.meterInstallationData[0].meterInstallation;
      if (SOdetails.meterInstallationData[0]) {
        SOdetails.projectStatus =
          SOdetails.meterInstallationData[0].projectStatus;
      }

      miData.isValid = true;
      miData.realDate = temp.date !== '0000-00-00' ? temp.date : '';
      miData.displayStatus = temp.status || "Not Started"

      // if (temp.status === 'Completed') {
      //   miData.displayStatus = 'Completed';
      // } else if (temp.status === 'In Progress') {
      //   miData.displayStatus = 'progress';
      // } else if (temp.status === 'TODO') {
      //   miData.displayStatus = 'Not Started';
      // } else if (temp.status === 'Canceled') {
      //   miData.displayStatus = 'hold';
      // } else {
      //   miData.displayStatus = 'alert';
      // }
      // if (miData.realDate === '') {
      //   miData.displayStatus = 'Not Started';
      // }
      // if (miData.displayStatus === 'progress' && miData.realDate) {
      //   SOdetails.projectStatus = 'In Progress';
      // }
      // if (
      //   SOdetails.projectStatus === 'Not Started' &&
      //   miData.realDate === '0000-00-00'
      //   ) {
      //     miData.displayStatus = 'todo';
      //   }
        // miData.displayStatus = temp.status || ""
        miData.realSlot = temp.slot || '';
    } else {
      if (SOdetails.projectStatus === 'Not Started') {
        miData.displayStatus = 'Not Started';
        miData.realData = '';
        miData.realSlot = '';
      }

      // if (SOdetails.meterInstallation.hasOwnProperty('meterInstallation')) {
      //   miData = SOdetails.meterInstallation.meterInstallation;
      //   if (miData.hasOwnProperty('date')) {
      //     miData.isValid = true;
      //     miData.realDate = miData.date;
      //     miData.displayStatus = 'future';
      //   }
      // }
    }

    console.log('miData', miData)

  } else {
    // If guest search, setup SODetails for step 1
    if (data.steps[0] && data.steps[0].projectDetails) {
      SOdetails = data.steps[0].projectDetails
      SOdetails.premiseID = ""
      SOdetails.projectAddress = ""
      SOdetails.contacts = {accountManager: {}, billing: {}, customer: {}}
      if (data.steps[0].premiseDetails) {
        SOdetails.projectAddress = data.steps[0].premiseDetails.projectAddress
        SOdetails.premiseID = data.steps[0].premiseDetails.premiseID
      }
      if (data.steps[0].contacts) {
        SOdetails.contacts.customer = data.steps[0].contacts[0]
        SOdetails.contacts.billing = data.steps[0].contacts[1]
      }
    }

    // If guest search, setup miData for step 8
    if (data.steps[7] && data.steps[7].installation && data.steps[7].installation.meterInstallation) {
      let temp = data.steps[7].installation.meterInstallation
      miData.realDate = temp.date !== "0000-00-00" ? temp.date : ""
      miData.displayStatus = temp.status || "Not Started"

      // if (temp.status === "Completed") {
      //   miData.displayStatus = "Completed"
      // } else if (temp.status === "In Progress") {
      //   miData.displayStatus = "progress"
      // } else if (temp.status === "TODO") {
      //   miData.displayStatus = "Not Started"
      // } else if (temp.status === "Canceled") {
      //   miData.displayStatus = "hold"
      // } else {
      //   miData.displayStatus = "alert"
      // }
      // if (miData.realDate === "") {
      //   miData.displayStatus = "Not Started"
      // }
      // if (miData.displayStatus === "progress" && miData.realDate) {
      //   SOdetails.projectStatus = "In Progress"
      // }
      // if (SOdetails.projectStatus === "Not Started" && miData.realDate === "0000-00-00") {
      //   miData.displayStatus = "todo"
      // }
      // miData.displayStatus = temp.status || ""
      miData.realSlot = temp.slot || ""
      console.log('before status', miData.displayStatus)
    } else {
      miData.displayStatus = "Not Started"
      miData.realDate = "0000-00-00"
    }
  }

  console.log('SODetails', SOdetails)

  const getSubheading = (step: any, icon = false) => {
    if (step.step_status) {
      if (step.display_order === 8 && isNotGuestSearch()) {
        if (
          SOdetails &&
          SOdetails.serviceType === 'Gas' &&
          miData &&
          miData.isValid
          // && SOdetails.meterInstallation
        ) {
          return miData.displayStatus
          // if (SOdetails.gateCode === 'G_1-7') {
          //   return 'Not Started';
          // }
          // return SOdetails.projectStatus || 'Status Unavailable';
        }
      }
      if (step.step_status === 'Completed' && !icon) {
        return safelyDisplayMMDDYY(step.completed_date) !== 'Invalid date' ? step.completed_date && (
          `${step.step_status}: ` + safelyDisplayMMDDYY(step.completed_date)
        ): "Completed";
      } else if (step.step_status === 'In Progress' && !icon) {
        return (
          step.step_status // Currently disabled, will be re-enabled: `- Target Completion Date: ` + safelyDisplayMMDDYY(step.completed_date)
        );
      } else {
        return step.step_status;
      }
    } else {
      return 'Not Started';
    }
  };

  const StepDetails = data.steps.map((step: Step) => (
    <StepAccordion
      key={step.step_id}
      title={step.step_label}
      titleParenthetical={step.typical_time_to_complete}
      iconName={getSubheading(step, true)}
      subheading={getSubheading(step)}
      stepNumber={step.display_order}
      open={step.step_status === 'In Progress'}
    >
      {staticDataSet[step.display_order - 1].stringText}
      <ErrorBoundary msg="Error loading task data for this step.">

      {/* All Steps - AssignmentTask - where contacts data exists and not "Not Required" or "Canceled" */}
      {step.contacts &&
      step.contacts.length > 0 &&
      step.step_id !== 'A00' &&
      step.step_status !== 'Not Required' ? (
        <AssignmentTask
          name={step.contacts[0].name}
          email={step.contacts[0].email}
          phone={step.contacts[0].phone}
          status={step.contacts[0].status}
          service={data.sub_service_type_id}
          step={step.step_id}
        />
      ): null}

      {/* Step 1 - SummaryTask */}
      {step.step_id === 'A00' && SOdetails ? (
        <SummaryTask data={SOdetails} />
      ) : null}

      {/* Step 2 - DocumentTask - Project Documents - Gas & Electric */}
      {step.step_id === 'B00' &&
      step.customer_documents &&
      step.step_status !== 'Not Required' &&
        step.step_status !== 'Canceled' ? (
        <DocumentTask
          type="project_documents"
          status={step.customer_documents.status}
          date={step.customer_documents.date}
          service={serviceType}
        >
         {StaticTaskContent.DocumentTask[serviceType]}
        </DocumentTask>
      ) : null}

      {/* Step 3 - MeetingTask - Site Meeting - Gas & Electric */}
      {step.step_id === 'C00' &&
      step.meeting &&
      step.step_status !== 'Not Required' ? (
        <MeetingTask
          startDate={step.meeting[0].date}
          endDate={step.meeting[0].endDate}
          label={step.meeting[0].title}
          status={step.meeting[0].status}
          type="site_meeting"
          service={serviceType}
        />
      ) : null}

      {/* Step 4 - DeliverableTask, Gas & Electric (Municipal Permit) */}
      {step.step_id === 'D00' &&
      (step.step_status === "In Progress" ||
       step.step_status ===  "Completed") &&
       step.deliverables.status &&
       (step.deliverables.status === "In Progress" ||
       step.deliverables.status === "Completed") ? (
        <div>
          <DeliverableTask
            status={step.deliverables.status}
          >
            {StaticTaskContent.DeliverableTask[serviceType]}
          </DeliverableTask>
        </div>
      ) : null}

      {/* Step 4 - StaticText - Site Requirements - Gas & Electric */}
      {step.step_id === 'D00' &&
      step.step_status !== 'Not Required' &&
      step.step_status !== 'Canceled'
      ? StaticTaskContent.SiteRequirements[serviceType] : null}

      {/* Step 4 - DocumentTask - Electric */}
      {(step.step_id === "D00" && isElectric &&
      (step.step_status !== "Not Required" &&
      step.step_status !==  "Canceled" &&
      step.tasks[0].status !== "Not Required" &&
      step.tasks[0].status !== "Canceled"))
        ? <DocumentTask
            date    = {step.tasks[0].date}
            label   = {step.tasks[0].name}
            status  = {step.tasks[0].status}
            type    = "signed_easement"
            service = "electric"
          >
            {StaticTaskContent.DocumentTaskEasement[serviceType]}
          </DocumentTask>
        : null
      }


      {/* Step 5 - AgreementTask - Gas & Electric */}
      {step.step_id === "E00" &&
      (step.step_status !== "Not Required" &&
      step.step_status !==  "Canceled" &&
      step.tasks[0].status !== "Not Required") ?
        <AgreementTask
          service = {serviceType}
          date    = {step.tasks[0].date}
          status  = {step.tasks[0].status}
          label   = {step.tasks[0].name}
        >
          {StaticTaskContent.AgreementTask[serviceType]}
        </AgreementTask>
        : null
      }

      {/* Step 5 - PaymentTask - gas & electric */}
      {step.step_id === 'E00' &&
      step.step_status !== 'Not Required' &&
        step.step_status !== 'Canceled' &&
        step.tasks[1].status !== 'Not Required' ? (
        <PaymentTask
          service={serviceType}
          date={step.tasks[1].date}
          status={step.tasks[1].status}
          label={step.tasks[1].name}
        />
      ) : null}

      {/* Step 5 - Document Task - Easement - Gas */}
      {step.step_id === 'E00' && isGas &&
      step.step_status !== 'Not Required' &&
        step.step_status !== 'Canceled' ? (
        <DocumentTask
          date={step.tasks[2].date}
          status={step.tasks[2].status}
          type="signed_easement"
          service="gas"
        >
          {StaticTaskContent.DocumentTaskEasement[serviceType]}
        </DocumentTask>
      ) : null}

      {/* Step 6 - ApprovalTask - Gas & Electric (Approval for Construction) */}
      {step.step_id === "F00" &&
      (step.step_status !== "Not Required" &&
      step.step_status !==  "Canceled" && step.tasks[0].status !== "Not Required") ?
          <ApprovalTask
              service = {serviceType}
              status  = {step.tasks[0].status}
          >
            {StaticTaskContent.ApprovalTask[serviceType]}
          </ApprovalTask>
          : null
      }

      {/* Step 7 - MeetingTask - Construction Schedule - gas & electric */}
      {step.step_id === 'G00' &&
      step.meeting &&
      step.step_status !== 'Not Required' ? (
        <MeetingTask
          date={step.meeting[0].date}
          label={step.meeting[0].title}
          status={step.meeting[0].status}
          type="construction_schedule"
          service={serviceType}
        />
      ) : null}

      {/* Step 8 - MeetingTask - Meter Installation - electric */}
      {step.step_id === 'H00' && isElectric &&
      step.meterInstallation &&
      step.step_status !== 'Not Required' ? (
        <MeetingTask
          date={newInstallationBlock.meterInstallation.date} //{Array.isArray(step.meterInstallation) ? step.meterInstallation[0].date : step.meterInstallation.date}
          label="Meter Installation"
          status={Array.isArray(step.meterInstallation) ? step.meterInstallation[0].status : step.meterInstallation.status}
          type="meter_install"
          service="electric"
        />
      ) : null}

      {/* { step.step_id === "H00" && console.log("miData", step , miData, dates, "data", data) } */}
      {/* Step 8 - MeetingTask - Meter Installation - gas */}
      {step.step_id === 'H00' &&
      SOdetails &&
      SOdetails.serviceType === 'Gas' &&
      miData &&
      (step.step_status !== 'Not Required') ? (
        // && isNotGuestSearch()
        <MeetingTask
          label="Meter Installation"
          date={miData.realDate} //{miData.realDate}
          // dates={dates}
          type="meter_install"
          status={miData.displayStatus} //{newInstallationBlock.serviceStatus || "Unknown"}
          slot={miData.realSlot} //{newInstallationBlock.meterInstallation.slot}
          SOdetails={SOdetails}
          // installationNum={data.serviceOrders.meterInstallation ? data.serviceOrders.meterInstallation.installationServiceOrder : ""}
          service="gas"
        />
      ) : null}

      {/* Step 8 - Installation Completion - electric || gas */}
      {step.step_id === 'H00' &&
      (step.step_status !== 'Not Required' && step.step_status !== 'Canceled' &&
      step.step_status !== 'On Hold') ? (
        <InstallationTask
          status={isGas ? miData.displayStatus : step.step_status}
          name={data.steps[6].contacts[0].name}
          email={data.steps[6].contacts[0].email}
          phone={data.steps[6].contacts[0].phone}
          service={SOdetails &&
            SOdetails.serviceType === 'Electric' ? "electric" : "gas"}
        />
      ) : null}


      </ErrorBoundary>
    </StepAccordion>
  ));

  const preamble = (
    <div>
      <h2>
        <span className="WO-step-overview-title">
          Step {inProgressStepNum} of {data.steps.length}
        </span>
        : {data.steps[inProgressStepNum - 1].step_label}
      </h2>
      <section>
        Status information is based on our most current records. Meeting the
        deadline for this project depends on completing the requirements of each
        phase before moving to the next phase. Weather could be a factor in
        changing the schedule.{' '}
        <CommonApprovalTimelinesModalLink
          gasOrElectric={data.service_type_label}
        />
      </section>
      <hr />
    </div>
  );

  return (
    <Card className="wo-page__steps">
      {preamble}
      {/* {loading && dates !== undefined ? <FullPageSpinner /> : StepDetails} */}
      {StepDetails}
    </Card>
  );
};

export default WorkOrderSteps;
