import React from "react";
import Card from "../../common/Card/Card";

const WelcomeCard: React.FunctionComponent = (): React.ReactElement => {
  return (
    <Card className="welcome-card">
      <h2>Welcome to our new Construction Management Center!</h2>
      <p>
        Builders who need electric or gas service for new construction or need
        to make changes to existing service connections can use this website to
        create and manage those service requests.
      </p>
      <p>
        Sign in to get started.
        {/*  New users,{" "}*/}
        {/*<a href={`${getDomain()}commercial/registration/index.jsp`}>*/}
        {/*  register now*/}
        {/*</a>*/}
        {/*.*/}
      </p>
    </Card>
  );
};

export default WelcomeCard;
