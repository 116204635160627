import React from "react";
import Card from "../Card/Card";
import getDomain from "../../../services/getDomain";

const WorkOrderProcessCard: React.FunctionComponent = (): React.ReactElement => {
  return (
    <Card>
      <h2>Work Order Process</h2>
      <p>
        Learn more about the work order process and what you’re responsible for
        to complete your project.
      </p>

      <p>

          <a
            className="underline"
            href={`${getDomain()}wps/wcm/connect/31ac77d4-6589-483c-aca7-f97effa681b1/PowerConnectionsForm.pdf?MOD=AJPERES`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Electric Installation Process &amp; Requirements
          </a>

      </p>

      <p>

          <a
            className="underline"
            href={`${getDomain()}wps/wcm/connect/b56f2d96-3c71-458b-8936-f85cbcd0ad32/GasRoadmapNewService.pdf?MOD=AJPERES`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Gas Installation Process &amp; Requirements
          </a>

      </p>

      <p>
        Learn more about planning and{" "}
        <u><a
          href={`${getDomain()}wps/wcm/connect/dte-web/quicklinks/landing-pages-temp/builders/builders`}
        >
          installing new service
        </a></u>
        .
      </p>
    </Card>
  );
};

export default WorkOrderProcessCard;
