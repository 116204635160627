import React, { useState } from "react";
import axios from "axios";
import AzureAD, {
  IAzureADFunctionProps,
  AuthenticationState,
  IAccountInfo
} from "react-aad-msal";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getDomain from "../../services/getDomain";
import WorkOrderSteps from "../../components/WorkOrderPage/WorkOrderSteps/WorkOrderSteps";
import WorkOrderOverview from "../../components/common/WorkOrderOverview/WorkOrderOverview";
import Card from "../common/Card/Card";
import ButtonPrimary from "../common/ButtonPrimary/ButtonPrimary";
import { authProvider } from "./authProvider";
import "./ESILoginCard.scss";


const ESILogInCard: React.FC<any> = () => {
  const [testResponse, setTestResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [WON, setWON] = useState(""); //53955399
  const [errMsg, setErrMsg] = useState("");

  const expiredToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IkN0VHVoTUptRDVNN0RMZHpEMnYyeDNRS1NSWSJ9.eyJhdWQiOiIzMmE1OWVmMy02YjRiLTRhZTQtOGYxYi0xMzE1Zjc4YWJjMGIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vOGU2MWQ1ZmUtNzc0OS00ZTc2LTg4ZWUtNmQ4Nzk5YWU4MTQzL3YyLjAiLCJpYXQiOjE1ODg2MjU5MTAsIm5iZiI6MTU4ODYyNTkxMCwiZXhwIjoxNTg4NjI5ODEwLCJhaW8iOiJBVFFBeS84UEFBQUFndTlneEpFZWlPK3ByRXVpdCtsTFB3dlhuSHBwUklMenlpZWtMZkYvejhZcllMbVBudVMwK2JMU1pEM2tsMmh2IiwibmFtZSI6IlBoaWxsaXAgV2lsbGlhbXMiLCJub25jZSI6IjNkZTg0N2NlLWY2OWItNDUxOS05MjZmLTFiYzRhNmQxYWM5OCIsIm9pZCI6IjI4NTJlNzIwLTM1YjAtNGRjNi05MTRhLTY0ZmViYjczNzMyNiIsInByZWZlcnJlZF91c2VybmFtZSI6InBoaWxsaXAud2lsbGlhbXNAZHRlZW5lcmd5LmNvbSIsInN1YiI6ImtPUjI2bjlYQkRPZ09MeVE5bG1HY1FCUXBCbzIwZmptYl9WN3lpRk84eU0iLCJ0aWQiOiI4ZTYxZDVmZS03NzQ5LTRlNzYtODhlZS02ZDg3OTlhZTgxNDMiLCJ1dGkiOiJlTGlEQnRZVFRrNkFVRUpRT2lLUUFBIiwidmVyIjoiMi4wIn0.lj3GSZ0cB18IFvP6nhwLQ_GlCsDXoMUrXTbqphFDxRbjqqswEI3v2ZyUxkkdV1h8HQW-Zv0xXhRCSOi5Imlk7ti6TfJOKpiI_Xau6QXkRyCJe-QEIX9tcbyyR9XsRVTDliiHlM1q0StAo92Rf6iQp3PYq9IYHgtYnT5HpjdSd2ygS5ElgRLxgECbg_a41IiVUpESUWHw-FuBQk-2_oj4DH4-THDrl6-HH0aw1GMXZsrXaXi5jjgJfWr-ACelm8bLZ1Rp16FjdTGkuZqXo29cUxxjY0kNK17iyPT0iGlt10aDs89yb4UWlRM2NCcTqcItqTYiILHM7DE0yvUtgxcmqw'

  const testRequest = (accountInfo:IAccountInfo | null, logout:() => void) => {
    // let endpoint = "https://cmc-test.azurewebsites.net/token";
    // let token = "";
    // let ESBEndpoint = `${getDomain()}api/employee/construction/workOrderManagement/v1/workorderStatus`;
    let cacheEndpoint = window.location.hostname.includes('localhost') || window.location.hostname.includes('nonprod')
      ? 'https://nonprod.api.cmc.customer.sites.dteenergy.com/employees/workOrderStatus'
      : 'https://api.cmc.customer.sites.dteenergy.com/employees/workOrderStatus'
    setLoading(true);
    // accountInfo && accountInfo.account &&

    axios.get(`${cacheEndpoint}?WONum=${WON}`, {
      headers: {
        'CMC-Who-Am-I': sessionStorage.getItem('msal.idtoken') || ""
      }
    }).then((r: any) => {
        setTestResponse(r.data);
        setLoading(false);
        setErrMsg("");
      })
      .catch((e: any) => {
        console.log('ERROR', e)
        let errMsg = "Could not retrieve any work order data";
        if (e.response && e.response.data) {
          errMsg = e.response.data.message;
        }        
        if (e.response && e.response.status)  {
          switch(e.response.status) {
            case 401:
              logout();
            case 504:
              errMsg = "Request timed out. Please try again later";
          }
        }

        setLoading(false);
        setErrMsg(errMsg);
        // setTestResponse(e)
    });
  };

  const handleWONInput = (e: any) => {
    let v = e.target.value;
    v = v.replace(/[^\d]/g, "");
    if (v.length > 8) {
      v = v.substr(0, 8);
    }
    setWON(v);
  };

  const errorCard = (
    <Card>
      <div className="wo-page__search--err">{errMsg}</div>
    </Card>
  );

  const getWorkOrderOverview = () => {
    // let data = JSON.parse(testResponse)
    let data: any = testResponse;
    return (
      <>
        <WorkOrderOverview address={data.address} workOrder={data} />
        <WorkOrderSteps data={data} />
      </>
    );
  };



  const getSearchForm = (accountInfo:IAccountInfo | null, logout:() => void) => {
    return (
      <>
        <h1 className="dashboard-page__welcome-heading">
          Hi
          {accountInfo && accountInfo.account.name.split(" ")[0]
            ? ", " + accountInfo.account.name.split(" ")[0]
            : " there"}
          !
          <span>
            (<a onClick={() => logout()}>Sign Out</a>)
          </span>
        </h1>
        <Card className="">
          <div className="wo-page__search">
            <input
              type="text"
              placeholder="8-Digit Work Order Number"
              value={WON}
              onChange={handleWONInput}
            />
            <button
              type="button"
              onClick={() => testRequest(accountInfo, logout)}
              className="btn-primary btn-log-in"
              disabled={loading || WON.length < 8}
            >
              Search
              <FontAwesomeIcon
                className={
                  loading
                    ? "log-in-contents--loading-spinner-loading"
                    : "log-in-contents--loading-spinner"
                }
                icon={faSpinner}
                spin
              />
            </button>
          </div>
          <div></div>
        </Card>
      </>
    );
  };

  return (
    <AzureAD provider={authProvider}>
      {({ login, logout, authenticationState, accountInfo }: IAzureADFunctionProps) => {

        const isAuthenticated =
        authenticationState === AuthenticationState.Authenticated;

        return (
          <div className={`esi-container ${isAuthenticated ? `search` : ``}`}>
            {isAuthenticated ? (
              getSearchForm(accountInfo, logout)
            ) : (
              <Card className="esi-v1">
                <div
                  className="log-in-contents"
                  style={{ textAlign: "center" }}
                >
                  <p>DTE Employee Portal</p>
                  <ButtonPrimary className="btn-log-in" onClick={login}>
                    Get Started
                  </ButtonPrimary>
                </div>
              </Card>
            )}
            {errMsg ? errorCard : testResponse ? getWorkOrderOverview() : null}
          </div>
        );
      }}
    </AzureAD>
  );
};

export default ESILogInCard;
