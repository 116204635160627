import React, { useContext, useState } from "react";
import "./ESILandingPage.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";
// import LogInCard from "./LogInCard/LogInCard";
// import WelcomeCard from "./WelcomeCard/WelcomeCard";
import WorkOrderProcessCard from "../common/WorkOrderProcessCard/WorkOrderProcessCard";
import ContactUsCard from "../common/ContactUsCard/ContactUsCard";
import HelpAndResourcesCard from "../LandingPage/HelpAndResourcesCard/HelpAndResourcesCard";
import HowItWorks from "../common/HowItWorksCard/HowItWorksCard";
import { UserSessionContext } from "../../contexts/userSessionContext";
import GuestWorkOrderPage from "../WorkOrderPage/GuestWorkOrderPage";
import ESILoginCard from "./ESILoginCard"

const ESILandingPage = (props: RouteComponentProps): React.ReactElement => {
  document.title = "CMC - Employee Portal";

  const bottomInfo = (
    <section className="narrow">
      <HowItWorks />
      <WorkOrderProcessCard />
      <div className="contact-us-and-help-resources-container">
        <HelpAndResourcesCard className="landing-page__help-and-resources-card" />
        <ContactUsCard className="landing-page__contact-us-card" />
      </div>

    </section>
  );

  const landingPageContent = (
    <div className="esi-landing-page">
      <ESILoginCard/>
      {/* <LogInCard
        setGuestSearchWON={setGuestSearchWON}
        setStaticModalOpen={setStaticModalOpen}/>
      <WelcomeCard />
      {staticModalOpen && <StaticModal
            open={true}
            className={`open`} //isErrorModalOpen ? `open` : ``}
            text={cacheHandler.get('staticModalText') || "Sorry, an error occurred"}
            heading={cacheHandler.get('staticModalTitle') || ""}
            buttonText={cacheHandler.get('staticModalButtonText') || ""}
      />} */}
      { bottomInfo }
    </div>
  );

  return landingPageContent;
};

export default withRouter(ESILandingPage);
