
const getDomain = () => {
  // Local proxy, will be merged into env once env is resolved:
  // return "https://web-n1qa.dteco.com/";
  if (window.location.hostname === "localhost") {
    return "http://localhost:3666/";
  } else if (window.location.hostname === "bs-local.com") {
    return "http://bs-local.com:3666/";
  } else if (window.location.hostname === "precmc.dteenergy.com") {
    // return "https://ocx-prv.dteco.com/"
    return "https://web-qa.dteco.com/";
  } else if (window.location.hostname === "cmc.dteenergy.com") { 
    return "https://newlook.dteenergy.com/";
  } else {
    return "https://web-n1qa.dteco.com/";
  }

  // if (!process.env.REACT_APP_API_ENV) {
  //   throw new Error(
  //     `getDomain.ts: REACT_APP_API_ENV is not defined in the project's .env file. Please add this variable.`
  //   );
  // }

  // const env = process.env.REACT_APP_API_ENV.toLowerCase() as ("qa" | "dev" | "n+1" | "prod");
  //
  // // TODO: implement dev environment
  // switch (env) {
  //   case "dev":
  //     throw new Error(
  //       `getDomain.ts: the dev environment mode is not yet implemented`
  //     );
  //   case "qa":
  //     return "https://web-qa.dteco.com/";
  //   case "n+1":
  //     return "https://web-n1qa.dteco.com/";
  //   case "prod":
  //     return "https://www.newlook.dteenergy.com/";
  //   default:
  //     throw new Error(
  //       `getDomain.ts: the value of REACT_APP_API_ENV in the project's .env file, '${env}' doesn't match any expected values. Please enter 'dev', 'qa', 'n+1', or 'prod'.`
  //     );
  // }
};

export default getDomain;
