import React from "react";
import FullPageSpinner from "../common/FullPageSpinner/FullPageSpinner";
import WorkOrderOverview from "../common/WorkOrderOverview/WorkOrderOverview";
import WorkOrderSteps from "./WorkOrderSteps/WorkOrderSteps";
import Card from "../common/Card/Card";
import "./WorkOrderPage.scss";

const MockWorkOrderPage: React.FC = (): React.ReactElement => {
  
  // Use this block to load mock data for Project Details
  const match = { params: { num: "12345678" } }
  const errorMessage = ""
  const loading = false;
  const data = require('./mockDetails.json')

  return (
    <main className="narrow wo-page">
      {data && (
        <div>
          <WorkOrderOverview
            address={data.address}
            workOrder={data}
          />
          <WorkOrderSteps data={data} />
        </div>
      )}
      {loading && match.params.num ? <FullPageSpinner /> : null}
      {errorMessage && match.params.num ? (
        <Card>
          {errorMessage && (
            <div className="wo-page__search--err">{errorMessage}</div>
          )}
        </Card>
      ) : null}
    </main>
  );
};

export default MockWorkOrderPage;