import React from "react";
import "./ErrorPage.scss";
import Card from "../common/Card/Card";

const ErrorPage = (props: React.PropsWithChildren<{}>): React.ReactElement => {
  return (
    <main className="error-page">
      <div className="error-page__hero" aria-hidden="true" />
      <div className="error-page__message-container">
        <Card>{props.children}</Card>
      </div>
    </main>
  );
};

export default ErrorPage;
