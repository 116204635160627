import React, { useState, useContext } from "react";
import axios from "axios";
import getDomain from "../../../services/getDomain";
import { UserSessionContext } from "../../../contexts/userSessionContext";
import ShowMoreList from "../../common/ShowMoreList/ShowMoreList";
import FullPageSpinner from "../../common/FullPageSpinner/FullPageSpinner";
import ManageContactsListItem from "./ManageContactsListItem/ManageContactsListItem";
import { cacheHandler } from "../../../utilities/cacheUtils";
import { getActiveBPID } from "../../../services/getAccountData";
import "./ManageContactsList.scss";


const ManageContactsList = () => {
  const { userSession } = useContext(UserSessionContext);
  const [delegates, setDelegates] = useState([]);
  const [delegatesLoaded, setDelegatesLoaded] = useState(false);
  const [data, setData] = useState<any>(null);

  let bpID = cacheHandler.get("ActiveBPID") 

  const fnInit = async() => {
    if(userSession) {
      await getActiveBPID(userSession.user.uid).then(id => {
        bpID = id
      })
    }
    
    !data &&
    !delegatesLoaded &&
    bpID &&
    userSession &&
    await axios.get(`${getDomain()}api/construction/usersManagement/v1/users/businessId/${bpID}`, { withCredentials: true })
    .then(r => {
      r.data && r.data.users && r.data.users.length > 0 && setDelegates(r.data.users.filter((d:any) => d.email.toUpperCase() !== userSession.user.id.toUpperCase()));
      setData(delegates)
      setDelegatesLoaded(true);
      cacheHandler.set('DelegateData', JSON.stringify(delegates));
    })
    .catch(e => {
      console.log('error',e);
      setDelegatesLoaded(true);
    });
  }
  fnInit();
  
  return delegatesLoaded ? (
    <ShowMoreList<any>
      IDPropName="email"
      className="manage-contacts-page__show-more-list"
      data={data}
      includeSearch
      searchPlaceholder="Search Contacts"
      searchableKeys={["delegateFirstName", "delegateLastName", "email", "phone"]}
      initialNumberDisplayed={5}
      emptyDataText="You currently have no contacts."
      scrollToTopButton
    >
      {delegatesLoaded && delegates.map((item: any) =>
        <ManageContactsListItem
        id={item.delegateBP}
        key={item.email}
        firstName={item.delegateFirstName || "Empty value"}
        lastName={item.delegateLastName || "Empty value"}
        email={item.email || "Empty value"}
        title={item.title || ""}
        phone={item.phone || "Empty value"}
        status={item.userStatus|| ""}
        projects={item.projects || []}
      />
      )}
    </ShowMoreList>
  ): !delegatesLoaded && <FullPageSpinner />;
};

export default ManageContactsList;
