import React from 'react';
import Task from './Task';
import { standardizeStatus, formatDate } from '../../../utilities/utilities';
let moment = require('moment-business-days');

moment.updateLocale('us', {
    workingWeekdays: [1, 2, 3, 4, 5]
});

const PaymentTask: React.FC<{
    label?   : string;
    status?  : string;
    children?: any;
    date     : any;
    service  : "gas" | "electric";
}> = ({
    label = "Payment",
    status,
    children,
    date,
    service,
}): any => {{

    const currentDate     = moment();
    const propDate        = moment(date);

    const dueDate         = service === "electric" ? propDate.businessAdd(5) : propDate;
    const businessDayDiff = currentDate.businessDiff(dueDate, 'days');
    const diffTime        = Math.abs(currentDate - dueDate);
    const dayDiff         = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const longDayDiffCheck = currentDate.businessDiff(propDate, 'days'); // this accounts for the data difference for expired soon and expired

    const hasOccurred     = moment().isAfter(dueDate) ? true : false;

    const dueSoonRange    = {
        gas         :   { dueSoon : 5, expireSoon  :   60, expired     :   90 },
        electric    :   { dueSoon : 5, expireSoon  :   150, expired     :   180 },
    };

    const calcuateDataStatus = () => {
        if(date){
            // if dueDate is today
            if(businessDayDiff === 0){
                return "due_today";
            }
            if(businessDayDiff >= 1 && hasOccurred) {
                if(longDayDiffCheck >= dueSoonRange[service].expired){
                    return "expired";
                }
                else if(longDayDiffCheck >= dueSoonRange[service].expireSoon){
                    return "expiring_soon";
                }

                return "overdue";

            } else {
                // if dueDate is 2 or less business days away
                if(businessDayDiff <= dueSoonRange[service].dueSoon + 1 && !hasOccurred){
                    return "due_soon";
                }
            }
        }
        return "invoice_ready";

    }

    const getStatus = () => {
        // status options: not_started, invoice_ready, due_today, due_soon, overdue, processing, completed, billable_customer, not_required, expiring_soon, expired
        switch(status && standardizeStatus(status)){
            case "invoice":
            case "billable_customer":
                return "billable_customer";
            case "in_review":
            case "in_progress":
                return calcuateDataStatus();
            case "processing":
                return "processing";
            case "completed":
                return "completed";
            case "not_required":
                return "not_required";
            case "not_started":
                return "not_started"
            case "to_do":
                return "not_started";
            default:
                return "not_started";
        };
    };
    const getStatusColor = () => {
        switch(getStatus()){
            case "completed":
            case "billable_customer":
            case "not_required":
                return "green";
            case "processing":
            case "due_soon":
            case "due_today":
                return "yellow";
            case "overdue":
            case "expiring_soon":
            case "expired":
                return "red"
            case "invoice_ready":
                return "default";
            case "not_started":
            default:
                return "grey";
        };
    };
    const getMessage = () => {
        switch(getStatus()){
            case "completed":
                return messages.completed;
            case "billable_customer":
                return messages.billable_customer;
            case "not_required":
                return messages.not_required;
            case "processing":
                return messages.processing;
            case "due_soon":
                return messages.due_soon;
            case "due_today":
                return messages.due_today;
            case "overdue":
                return messages.overdue;
            case "invoice_ready":
                return messages.invoice_ready;
            case "expiring_soon":
                return messages.expiring_soon;
            case "expired":
                return messages.expired;
            case "not_started":
            default:
                return messages.not_started;
        };
    };
    const getIcon = () => {
        switch(getStatus()){
            case "invoice_ready":
                return "in_progress"
            case "completed":
            case "billable_customer":
            case "not_required":
                return "completed";
            case "processing":
                return "pending";
            case "due_soon":
            case "due_today":
            return "alert";
            case "overdue":
            case "expiring_soon":
                return "emergency";
            case "expired":
                return "canceled"
            case "not_started":
            default:
                return "future";
        };
    };
    const getIconAltText = () => {
		switch(getIcon()){
			case "completed":
                return "Approved";
            case "in_progress":
                return "To-Do";
            case "pending":
                return "Pending Approval";
            case "alert":
                return "Due Soon/Upcoming";
            case "emergency":
                return "Overdue";
            case "future":
                return "Future";
		};
    };

    const messages = {
        not_ready: `${service === "electric" ? `Your Agreement for Service` : `Your invoice`} has not been sent to you.`,
        invoice_ready: `${service === "electric" ? `Your Agreement for Service` : `Your invoice`} has been sent to you. Please review it and submit payment.`,
        due_soon: `Your payment is due ${service === `electric` ? `in ${dayDiff} day${dayDiff > 1 ? `s` : ""}` : `on ${formatDate(dueDate)}`}. Please submit it to avoid project delays.`,
        due_today: `Your payment is due today. Please submit it to avoid project delays.`,
        overdue: `Your payment was due on ${formatDate(dueDate)}. Please submit it to avoid further project delays.`,
        expiring_soon: `Your Agreement for Service is expiring soon and your payment has not been processed. Please make your payment or your work order will be canceled.`,
        expired: `Your Agreement for Service has expired and your payment has not been processed. Please contact your planner if you want to continue your project.`,
        processing: `Your payment has been received and is being processed.`,
        completed: `Your payment has been processed.`,
        not_required: `No payment is required at this time.`,
        billable_customer: `No payment is required at this time. If a future payment is required, an invoice will be prepared and sent to you.`,
        not_started: `Your Agreement for Service has not been sent to you. `
    };
    const visibleDueDateStatuses = [
        "invoice_ready",
        "due_soon",
        "due_today",
        "overdue",
        "processing",
        "completed"
    ];

    return(
        <Task
            className   = "payment-task"
            statusColor = {getStatusColor()}
            status      = {getStatus()}
            label       = {label}
            icon        = {getIcon()}
            iconAltText = {getIconAltText() || null}
        >
            <p>{service === "gas" ? "Your DTE representative will provide an invoice detailing the installation costs. Payment is due before your job can be scheduled for construction." : "Your invoice will provide details regarding all installation costs. Payment is due before your job can be scheduled for construction."}</p>
            {children}
            <h5 className="message">{getMessage() || null}</h5>
            { date && getStatus() !== "completed" && (visibleDueDateStatuses.includes(getStatus()) && dueDate) && (
                <div className="due-wrapper">Due: {formatDate(dueDate)}</div>
            )}
        </Task>
    );
}};

export default PaymentTask;
