import React from "react";
import ErrorPage from "../ErrorPage";
import ButtonPrimary from "../../common/ButtonPrimary/ButtonPrimary";

const NotFoundCard: React.FunctionComponent = (): React.ReactElement => {
  return (
    <ErrorPage>
      <h2>Page Not Found</h2>
      <p>The page you are trying to view does not exist or has changed locations.</p>
      <ButtonPrimary to="/">Home</ButtonPrimary>
    </ErrorPage>
  );
};

export default NotFoundCard;
