import React from "react";
import ButtonToInviteContactsModal from "./ButtonToInviteContactsModal/ButtonToInviteContactsModal";
import AccessLevelModalLink from "../common/AccessLevelModalLink/AccessLevelModalLink";
import ManageContactsList from "./ManageContactsList/ManageContactsList";
import AccessDeniedCard from "../ErrorPage/AccessDeniedCard/AccessDeniedCard";
import { cacheHandler } from "../../utilities/cacheUtils";
import "./ManageContactsPage.scss";

const ManageContactsPage: React.FC<any> = (props:any): React.ReactElement => {
  document.title = "CMC - Contacts";

  const elevatedRole = cacheHandler.get('ElevatedRole') || "View";
  const permittedContacts = elevatedRole !== "NA" ? (

    <main id="manage-contacts-page" className="manage-contacts-page narrow">
    <div>
        <h1>Your Contacts</h1>
        <h3>Invite New Contact</h3>
        <p>
          Invite anyone to track, view and manage your ongoing projects. You can
          provide each contact a specific level of access.
        </p>
        <br />
        <ButtonToInviteContactsModal />
        <h3>Manage Contacts</h3>
        <div>
          Manage the level of access for your existing contacts.{" "}
          <p style={{fontStyle:"italic",color:"#999"}}>* Some buttons, links and clickable features are not available at this time. </p>
            <AccessLevelModalLink />
        </div>
        <br />
        <ManageContactsList />
    </div>
      </main>

  ) : <AccessDeniedCard/>;

  return permittedContacts;
};

export default ManageContactsPage;
