import React from "react";
import AccordionWithTitle from "../../common/AccordionWithTitle/AcordionWithTitle";
import "./FAQAccordion.scss";

interface FAQAccordionProps {
  question: string;
  icon?: React.ReactElement;
  includeConnectingLine?: boolean;
}

const FAQAccordion = (props: React.PropsWithChildren<FAQAccordionProps>) => {
  const icon = (
    <div className="faq-accordion__icon">
      <div className="faq-accordion__icon__vertical-line" />
      <div className="faq-accordion__icon__horizontal-line" />
    </div>
  );

  return (
    <AccordionWithTitle
      includeConnectingLine={false}
      headingText={props.question}
      icon={icon}
      className="faq-accordion"
      tabIndex
    >
      {props.children}
    </AccordionWithTitle>
  );
};

export default FAQAccordion;
