import React, { useContext } from "react";
import NumberFormat from "react-number-format";
import ButtonPrimary from "../../../common/ButtonPrimary/ButtonPrimary";
import { ModalContext } from "../../../../contexts/modalContext"
import { states } from "../../../../utilities/utilities"
import "./UpdateProfileInfoModalContent.scss";

const UpdateProfileInfoModalContent = () => {

  const { setModalOpen } = useContext(ModalContext);

  const data = {
    name: "John Smith",
    company: "Acme Builders",
    position: "Construction Manager",
    phone: "123-123-1234",
    address: {
      street: "1418 ARCHWOOD DR",
      unit: "",
      city: "OAKLAND",
      state: "MI",
      zip: "48363-1705"
    },
    email: "john.smith@gmail.com"
  };

  return (
    <div className="update-profile-info-modal">
      <div className="update-profile-info-modal__explanation-paragraph">
        Update your profile and contact information. Updating this information
        will update your default contact information visible to other project
        contacts.
    </div>
      <form className="update-profile-info-modal__form">
        <div>
          <label htmlFor="name">
            Name*
          <input
              name="name"
              id="name"
              type="text"
              placeholder="Name"
              aria-label="name"
              value={data.name}
            // onChange={(event): void => changeHandler(event)}
            // onBlur={blurHandler}
            />
          </label>
        </div>
        <div>
          <label htmlFor="company">
            Company
          <input
              name="company"
              id="company"
              type="text"
              placeholder="Name"
              aria-label="company"
              value={data.company}
            // onChange={(event): void => changeHandler(event)}
            // onBlur={blurHandler}
            />
          </label>
          <label htmlFor="position">
            Position
          <input
              name="position"
              id="position"
              type="text"
              placeholder="Name"
              aria-label="position"
              value={data.position}
            // onChange={(event): void => changeHandler(event)}
            // onBlur={blurHandler}
            />
          </label>{" "}
        </div>
        <div>
          <label htmlFor="phone">
            Phone
          <NumberFormat
              name="phone"
              id="phone"
              type="tel"
              format="(###) ###-####"
              placeholder="(   )   -"
              aria-label="phone"
              value={data.phone}
            // onChange={(event): void => changeHandler(event)}
            // onBlur={phoneBlurHandler}
            />
            {/* {phoneError && (
        <div className="other-error" data-testid="email-error">
          Phone number is incomplete.
        </div>
      )} */}
          </label>
        </div>
      </form>
      <h4>Billing Address</h4>
      <form className="update-profile-info-modal__form">
        <div>
          <label htmlFor="street">
            Street Address
          <input
              name="street"
              id="street"
              type="text"
              placeholder="Street"
              aria-label="street address"
              value={data.address.street}
            // onChange={(event): void => changeHandler(event)}
            // onBlur={blurHandler}
            />
          </label>
          <label htmlFor="unit">
            Unit
          <input
              name="unit"
              id="unit"
              type="text"
              placeholder="Unit"
              aria-label="unit"
              value={data.address.unit}
            // onChange={(event): void => changeHandler(event)}
            // onBlur={blurHandler}
            />
          </label>
        </div>
        <div>
          <label htmlFor="city">
            City
          <input
              name="city"
              id="city"
              type="text"
              placeholder="Name"
              aria-label="city"
              value={data.address.city}
            // onChange={(event): void => changeHandler(event)}
            // onBlur={blurHandler}
            />
          </label>
          <label htmlFor="state">
            State*
            <div className="select-wrapper">
              <select
              name="state"
              id="state"
              className="state"
            >
              {Object.keys(states).map(function (key: string) {
                if (data.address.state === key) {
                  return (
                    <option value={key} key={key} selected>{key}</option>
                  )
                } else {
                  return (
                    <option value={key} key={key}>{key}</option>
                  )
                }

              }
              )}
            </select>
            </div>
          </label>
        </div>
        <div>
          <label htmlFor="zipCode">
            Zip Code
          <input
              name="zipCode"
              id="zipCode"
              type="text"
              placeholder="Zip Code"
              aria-label="zipcode"
              value={data.address.zip}
            // onChange={(event): void => changeHandler(event)}
            // onBlur={blurHandler}
            />
          </label>
        </div>
      </form>
      <ButtonPrimary
        className="update-profile-info-modal__save-btn"
        onClick={() => { }}
      >
        Save
    </ButtonPrimary>
      <ButtonPrimary
        className="update-profile-info-modal__cancel-btn"
        onClick={() => { setModalOpen(false) }}
      >
        Cancel
    </ButtonPrimary>
    </div>
  );
};

export default UpdateProfileInfoModalContent;
