import React, { useState, useRef } from "react";
import Axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { withRouter, RouteComponentProps } from "react-router-dom";
import getDomain from "../../services/getDomain";
import Card from "../common/Card/Card";
import FullPageSpinner from "../common/FullPageSpinner/FullPageSpinner";
import WorkOrderOverview from "../common/WorkOrderOverview/WorkOrderOverview";
import WorkOrderSteps from "./WorkOrderSteps/WorkOrderSteps";
import "./WorkOrderPage.scss";

interface ChildComponentProps extends RouteComponentProps {
  match: any;
  history: any;
}
const GuestWorkOrderPage: React.FC<ChildComponentProps> = ({
  match, history
}): React.ReactElement => {
  // const initialPath = match.params.num
  //   ? paths.getWorkOrder + match.params.num
  //   : "";

  // console.log('location.state')
  // console.log(history.location.state)

  // let sitekeyByDomain = "";
  // if (window.location.href.split("id/")[0] === "http://localhost:3000/") {
  //   sitekeyByDomain = "6LfZbIgUAAAAAHTQPKcAP_93c2WdCalJrsDPrtLU";
  // } else if (
  //     window.location.href.split("id/")[0] === "https://testcmc.dteenergy.com/" ||
  //     window.location.href.split("id/")[0] === "https://precmc.dteenergy.com/"
  // ) {
  //   sitekeyByDomain = "6LfKESMTAAAAANcRYPMder7Lkur0vpuDmQWj0Fwa";
  // } else if (window.location.href === "https://cmc.dteenergy.com/") {
  //   sitekeyByDomain = "6LcFFCMTAAAAAAVybGZ_bkuzhptvub8-sCcm6w23";
  // }

    let getSitekey = () => {
      let hostname = window.location.href.split("/")[2];
      console.log(hostname)
      if (hostname === "localhost:3000") {
        return "6LfZbIgUAAAAAHTQPKcAP_93c2WdCalJrsDPrtLU";
      } else if (
          hostname === "testcmc.dteenergy.com" ||
          hostname === "precmc.dteenergy.com"
      ) {
        return "6LfKESMTAAAAANcRYPMder7Lkur0vpuDmQWj0Fwa";
      } else if (hostname === "cmc.dteenergy.com") {
        return "6LcFFCMTAAAAAAVybGZ_bkuzhptvub8-sCcm6w23";
      }
    return "SITEKEYNOTFOUNDINAPP";
    }

    const guestRecaptchaURL = `${getDomain()}api/anonymous/construction/workOrderManagement/v1/workorderStatus?WONum=`
    const guestURL = `${getDomain()}anonymous/construction/workOrderManagement/v1/workorderStatus?WRONGWONum=`
    const [num, setNum] = useState(match.params.num || "missingWON");
    const [recaptchaData, setRecaptchaData] = useState("")

    const wonField = useRef<HTMLInputElement>(document.createElement('input'));

    // window.location.hash=num


  // const { data, loading, errorMessage, trigger } = useAPI(
  //   guestURL+(cachedWON ? cachedWON : num),
  //   "get",
  //   {},
  //   1
  // );

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [done, setDone] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [WONErrorMessage, setWONErrorMessage] = useState("")

  if (history.location.state && history.location.state.rd) {
    if (recaptchaData !== history.location.state.rd) {
        setRecaptchaData(history.location.state.rd)
    }
  }
  if (!history.location.state) {
    history.push('/home')
  }


  const makeRequest = async () => {

    let relevantUrl = guestURL+num;
    if (history.location.state.rd !== 'again') {
      relevantUrl = guestRecaptchaURL+num + "&reCaptchaResponse="+recaptchaData
    }
    if (localStorage.getItem('recaptchaData')) {
        relevantUrl = guestRecaptchaURL+num + "&reCaptchaResponse="+localStorage.getItem('recaptchaData')
    }

    setFetching(true)
    !data && await Axios.get(relevantUrl,
    {withCredentials: true}
    )
    .then(r => {
      setData(r.data)
      setErrorMessage("")
      setFetching(false)
    })
    .catch(e => {
      !data && setErrorMessage((e.response && e.response.data.errorMessage) || "No information could be found for that work order number.")
      setFetching(false)
    })
    .then(() => {
      setLoading(false)
      setDone(true)
      setFetching(false)
      // localStorage.clear()
    })    
  }

  !data && !loading && !errorMessage && setLoading(true);
  !data && !done && !fetching && loading && !errorMessage && history.location.state && makeRequest()
  if (loading && done) {
    setDone(false)
    makeRequest()
  }
    // localStorage.getItem('cmcRecaptcha') &&



  const handleAnotherSearch = () => {
    console.log('triggering')

    if (wonField.current.value.length === 8) {
      setWONErrorMessage("")
      setErrorMessage("")
      localStorage.setItem('cmcWON', wonField.current.value);
      setNum(wonField.current.value);
      let newNum = wonField.current.value
      setLoading(true)

      history.push('/guest-search/'+newNum, { rd: recaptchaData })
      setData(null)
      // setRecaptchaData("")
      setRecaptchaValid(false)
      // setRecaptchaData('again')

      // Axios.get(guestURL+newNum)
      // .then(r => {
      //   setData(r.data)
      //   setErrorMessage("")
      //   setLoading(false)
      // })
      // .catch(e => {
      //   setLoading(false)
      //   setErrorMessage(e.response.data.errorMessage || "No information could be found for that work order number.")
      // })
      // .then(() => {
      //   setLoading(false)
      // })
      
      // trigger(guestURL + wonField.current.value)
    } else {
      console.log('under length')
      setWONErrorMessage("Your work order number must contain 8 digits")
    }
  }

  const validateWon = () => {
    wonField.current.value = wonField.current.value.replace(/[^\d-]/g, '');
    if (wonField.current.value.length > 8) {
      wonField.current.value = wonField.current.value.substr(0,8);
    }
  }
  // const [recaptchaData, setRecaptchaData] = useState({ rd: "rd"});
  const [recaptchaValid, setRecaptchaValid] = useState(false)
  const handleRecaptcha = (data: any) => {
    console.log('setting recaptcha data')
    console.log(data)
    setRecaptchaData(data)
    localStorage.setItem('recaptchaData', data)
    setRecaptchaValid(true)
  }

  return (
    <main id={""} className="narrow wo-page">
      {data && !errorMessage && !loading && (
        <div>
          <WorkOrderOverview
            address={data.address}
            workOrder={data}
          />
          <WorkOrderSteps data={data} />
        </div>
      )}
      {loading && match.params.num ? <FullPageSpinner /> : null}
      {errorMessage && match.params.num ? (
        <Card>
          {errorMessage && (
            <div className="wo-page__search--err">{errorMessage}</div>
          )}
        </Card>
      ) : null}
      {(data || errorMessage) && recaptchaData ? (
        <Card>
          <h2>
            Search Another Work Order
            {/* <FontAwesomeIcon
              className={
                loading
                  ? "log-in-contents--loading-spinner-loading"
                  : "log-in-contents--loading-spinner"
              }
              icon={faSpinner}
              spin
            /> */}
          </h2>
          <div className="wo-page__search">
            <input
              type="text"
              // className={errorMessage ? "err" : ""}
              // onChange={e => setNum(e.target.value)}
              placeholder="8-Digit Work Order Number"
              onChange={validateWon}
              ref={wonField}
            />
            <button
              type="button"
              style={{backgroundColor: recaptchaValid ? "" : "#aaa"}}
              disabled={!recaptchaValid}
              onClick={() =>
                handleAnotherSearch()
              }
            >
              Search
            </button>
          </div>
          <div>
            <ReCAPTCHA
                sitekey={getSitekey()}
                onChange={handleRecaptcha}
                size="normal"
            />
          </div>
          {WONErrorMessage && (
            <div className="wo-page__search--err">{WONErrorMessage}</div>
          )}
        </Card>
      ) : null}      
    </main>
  );
};

export default withRouter(GuestWorkOrderPage);
