import React from "react";

interface ProjectListItemProps {
  projectID?: string;
  premiseID?: string;
  id?: string;
}

const ProjectListItem: React.FC<ProjectListItemProps> = ({ children }) => {
  return <div className="dashboard-page__project-list-item">{children}</div>;
};

export default ProjectListItem;
