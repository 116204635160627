import React, {ReactNode, ReactElement} from 'react';
import Task from './Task';

const ApprovalTask: React.FC<{
    status: 'Not Started' | 'In Progress' | 'Completed' | 'On Hold';
    service: string;
    children?: ReactNode;
    label?: string;
}> = ({
    status,
    service,
    label = "Approval for Construction",
    children,
}): ReactElement => {{
    const statusKey = status.replace(' ', '_').toLowerCase()
    const icons = {
        future: {
            name: "future",
            altText: "To-Do"
        },
        in_progress: {
            name: "in_progress",
            altText: "In Progress"
        },
        completed: {
            name: "completed",
            altText: "Approved"
        },
        emergency: {
            name: "emergency",
            altText: "On Hold"
        }
    }
    const statuses: any = {
        not_started: {
            color: "grey",
            icon: icons.future,
            gas: `Your final site evaluation has not been completed and your job has not been approved for construction.`,
            electric: `Your DTE Planner has not performed the final site evaluation. Once completed, your job will be approved for construction. `
        },
        in_progress: {
            color: "default",
            icon: icons.in_progress,
            gas: `Your final site evaluation is in progress and your approval for construction is pending.`,
            electric: `Your final site evaluation is in progress and your approval for construction is pending.`
        },
        completed: {
            color: "green",
            icon: icons.completed,
            gas: `Your site has passed the final evaluation and been released for construction.`,
            electric:  `Your site has passed inspection and is ready for construction.`
        },
        on_hold: {
            color: "red",
            icon: icons.emergency,
            gas: `Your final site evaluation is on hold. Please contact your DTE representative if you have any questions or concerns.`,
            electric: `Your final site evaluation has not been completed and your job has not been approved for construction.`
        }
    }

    return(
        <Task
            className   = "agreement-task"
            statusColor = {statuses[statusKey].color}
            status      = {statusKey}
            label       = {label}
            icon        = {statuses[statusKey].icon.name}
            iconAltText = {statuses[statusKey].icon.altText}
            message     = {statuses[statusKey][service]}
        >
            {children}
        </Task>
    );
}};

export default ApprovalTask;
