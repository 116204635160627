import React, { useContext, useState, useEffect, useCallback, useRef, ChangeEvent } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import User from "../../../types/User";
import getDomain from "../../../services/getDomain";
import { ModalContext } from "../../../contexts/modalContext";
import { AccessLevel } from "../../../types/accessLevel";
import { ProjectListItemProps } from "../../../types/ProjectListItem";
import ButtonPrimary from "../../common/ButtonPrimary/ButtonPrimary";
import FullPageSpinner from "../../common/FullPageSpinner/FullPageSpinner";
import ShowMoreProjectsList from "../ShowMoreProjectsList/ShowMoreProjectsList";
import "./InviteContactsModalContent.scss";
import { cacheHandler } from "../../../utilities/cacheUtils";

import { getActiveBPID } from "../../../services/getAccountData";
import { UserSessionContext } from "../../../contexts/userSessionContext";


interface initialStateProps {
  email: string;
  confirmEmail: string;
  confirmEmailError: boolean;
  firstName: string;
  lastName: string;
  phone: string;
  phoneError: boolean;
}

interface InviteContactsModalContentProps {
  user?: User;
}

const InviteContactsModalContent1 = (props: InviteContactsModalContentProps) => {
  const { userSession } = useContext(UserSessionContext);
  let bpID = cacheHandler.get("ActiveBPID") 

  const { modalOpen, setModalOpen, setModalToBeScrolled } = useContext(ModalContext);
  const [atLeastOneChangeMade, setAtLeastOneChangeMade] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);
  const [atLeastOneProjectSelected, setAtLeastOneProjectSelected] = useState(false);
  const [loading, setLoading] = useState(true)
  const [projectList, setProjectList] = useState<ProjectListItemProps[]>([])


  const initialState: initialStateProps = {
    email: "",
    confirmEmail: "",
    confirmEmailError: false,
    firstName: "",
    lastName: "",
    phone: "",
    phoneError: false
  };
  const [
    {
      email,
      confirmEmail,
      confirmEmailError,
      firstName,
      lastName,
      phone,
      phoneError
    },
    setState
  ] = useState(initialState);
  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if(!projectList.length) {
      let asyncCall = ( async () => {
        await getProjectsList()
      })
      asyncCall()
      // await getProjectsList()
    }
  }, []);

  const getProjectsList = async () => {
    
    if(userSession) {
      await getActiveBPID(userSession.user.uid).then(id => {
        bpID = id
      })
    }

    let parsedProjectsList = await axios
        .get(
          `${getDomain()}api/construction/usersManagement/v1/users/accountSummary/businessId/${bpID}`,
          { withCredentials: true }
        )
        .then(r => {
          if (r.data && r.data.projectsList) {
          return r.data.projectsList
          }
        })
        .catch(e => {
          //catch error
    });
    
    let newProjectsList = parsedProjectsList.map((project: any) => {
      project.loggedInUserRole = project.userRole;
      project.userRole = "View";
      return project;
    });

    let accessibleProjectsList = newProjectsList.filter(
      (pp: any) =>
        pp.loggedInUserRole !== "View" && pp.loggedInUserRole !== "Edit"
    );
    setLoading(false)
    setProjectList(accessibleProjectsList);
  }

  useEffect(() => {
    if (!modalOpen) {
      clearInputs();
      clearSelectedItems();
      // resets/removes required fields from input elements when closing the modal
      resetInputs();
      setCanceled(false);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (firstName && lastName && email && atLeastOneProjectSelected) {
      setIsFormReady(true)
    } else {
      setIsFormReady(false)
    }
  }, [confirmEmailError, firstName, lastName, atLeastOneProjectSelected])

  const clearInputs = useCallback(() => {
    setState({ ...initialState });
  }, [initialState]);

  const resetInputs = () => {
    let inputs = document.querySelectorAll('input');
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].required) {
        inputs[i].required = false
      }
    }
  }
  //form

  const changeHandler = (
    event: ChangeEvent<
      HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement
    >
  ) => {
    if (event.type === "change") {
      const { name, value } = event.target;
      setState(prevState => ({ ...prevState, [name]: value }));
      setAtLeastOneChangeMade(true);
    }
  };

  const blurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.type === "blur") {
      event.target.setAttribute("required", "true");
    }
  };

  const confirmBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.setAttribute("required", "true");
    if (email !== confirmEmail) {
      setState(prevState => ({ ...prevState, confirmEmailError: true }));
    } else {
      setState(prevState => ({ ...prevState, confirmEmailError: false }));
    }
  };

  const phoneBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.setAttribute("required", "true");
    const phoneNum = phone.replace(/[- )(]/g, "");
    if (phoneNum.length === 10 || phoneNum.length === 0) {
      setState(prevState => ({ ...prevState, phoneError: false }));
    } else {
      setState(prevState => ({ ...prevState, phoneError: true }));
    }
  };

  const inviteNewContactsForm = (
    <form className="invite-new-contact-modal__form">
      <label htmlFor="email">
        Email Address*
        <input
          name="email"
          type="email"
          placeholder="example@example.com"
          aria-label="email"
          value={email}
          onChange={(event): void => changeHandler(event)}
          onBlur={e => {
            blurHandler(e);
            confirmBlurHandler(e);
          }}
          ref={emailInputRef}
        />
        <div className="error email-error" data-testid="email-error">
          Please enter a valid email address. (ex: name@email.com)
        </div>
      </label>
      <label htmlFor="confirm-email">
        Confirm Email Address*
          <input
          name="confirmEmail"
          type="email"
          aria-label="confirm email"
          placeholder="example@example.com"
          value={confirmEmail}
          onChange={(event): void => changeHandler(event)}
          onBlur={confirmBlurHandler}
          ref={emailInputRef}
        />
        {confirmEmailError ? (
          <div className="error-wrapper">
            <span className="other-error" data-testid="email-error">
              Email values do not match. &nbsp;
              </span>
          </div>
        ) : (
            <div className="error-wrapper">
              <div className="error email-error" data-testid="email-error">
                Please confirm your email address.
              </div>
            </div>
          )
        }
      </label>
      <label htmlFor="first-name">
        First Name*
        <input
          name="firstName"
          type="text"
          aria-label="first name"
          placeholder="First Name"
          value={firstName}
          onChange={(event): void => changeHandler(event)}
          onBlur={blurHandler}
        />
        <div className="error email-error" data-testid="email-error">
          Please enter a first name.
        </div>
      </label>
      <label htmlFor="last-name">
        Last Name*
        <input
          name="lastName"
          type="text"
          aria-label="last name"
          placeholder="Last Name"
          value={lastName}
          onChange={(event): void => changeHandler(event)}
          onBlur={blurHandler}
        />
        <div className="error email-error" data-testid="email-error">
          Please enter a last name.
        </div>
      </label>

      <label htmlFor="phone">
        Phone Number
        <NumberFormat
          name="phone"
          type="tel"
          format="(###) ###-####"
          placeholder="(   )   -"
          aria-label="phone"
          value={phone}
          onChange={e => {
            changeHandler(e);
          }}
          onBlur={e => {
            phoneBlurHandler(e);
          }}
          id={phoneError ? "phone-error" : ""}
        />
        {phoneError && (
          <div className="other-error" data-testid="email-error">
            Phone number is incomplete.
          </div>
        )}
      </label>
    </form>
  );

  //Show more list

  const [selectAllSelected, setSelectAllSelected] = useState(false);
  const [selectAllValue, setSeletAllValue] = useState<AccessLevel>("View");

  const handleSelectAll = () => {
    setSelectAllSelected(!selectAllSelected);
    const newData = projectList.map((item:ProjectListItemProps) => {
      const newItem = { ...item };
      newItem.selected = !selectAllSelected;

      return newItem;
    });
    setProjectList(newData);
    setAtLeastOneProjectSelected(!selectAllSelected);
  };

  const handleSetAllRoles = (accessLevel: AccessLevel) => {
    const newData = projectList.map((item:ProjectListItemProps) => {
      const newItem = { ...item };
      if (newItem.selected) {
        newItem.userRole = accessLevel;
      }
      return newItem;
    });

    setProjectList(newData);
    setSeletAllValue(accessLevel);
  };

  const clearSelectedItems = () => {
    setAllItemsSelectedTo(false);
  };

  const setAllItemsSelectedTo = (selected: boolean) => {
    const newData = projectList.map((item:ProjectListItemProps) => {
      const newItem = { ...item };
      newItem.selected = selected;

      return newItem;
    });

    if (selected) {
      setDisplayButtonContainerOnMobile(true);
    }
    setProjectList(newData);
  };

  const handleCheckboxToggle = (id: string) => {
    const newData = projectList.map((item:ProjectListItemProps) => {
      if (item.premiseID === id) {
        const newItem = { ...item };
        newItem.selected = !newItem.selected;
        return newItem;
      }
      return item;
    });

    const selectedItems = newData.filter((item:ProjectListItemProps) => {
      return item.selected;
    });

    setAtLeastOneProjectSelected(selectedItems.length > 0);

    if (selectedItems.length > 0) {
      setDisplayButtonContainerOnMobile(true);
    }
    setProjectList(newData);
  };

  const workOrderSelectChangeHandler = (
    id: string,
    accessLevel: AccessLevel
  ) => {
    const newData = projectList.map((item:ProjectListItemProps) => {
      if (item.premiseID === id) {
        const newItem = { ...item };
        newItem.userRole = accessLevel;
        return newItem;
      }
      return item;
    });

    setProjectList(newData);
  };

  const showMoreList = (
    <>
      <h4>Project Assignment</h4>
      <p>
        Invite your new contact to existing projects by selecting one or more
        projects. Please specify their access level below.
      </p>
      {loading ? 
        <FullPageSpinner />
       : (
        <ShowMoreProjectsList 
          projectList={projectList}
          clearSelectedItems={clearSelectedItems}
          handleCheckboxToggle={handleCheckboxToggle}
          workOrderSelectChangeHandler={workOrderSelectChangeHandler}
          selectAllSelected={selectAllSelected}
          handleSelectAll={handleSelectAll}
          selectAllValue={selectAllValue}
          handleSetAllRoles={handleSetAllRoles}
        />
      )}
    </>
  );

  //confirm modal

  const defaultConfirmModalContent = (
    <>
      <h2>Are You Sure?</h2>
      <div className="invite-new-contact-modal__explanation-paragraph">
        Are you sure you want to leave without saving changes?
        </div>
      <ButtonPrimary
        className="invite-new-contact-modal__button-container-save-btn"
        onClick={() => {
          window.location.reload()
        }}
      >
        Close
        </ButtonPrimary>
      <ButtonPrimary
        className="invite-new-contact-modal__button-container-cancel-btn"
        onClick={() => {
          setCanceled(false);
        }}
      >
        Cancel
        </ButtonPrimary>
    </>
  );

  const [confirmModalContent, setConfirmModalContent] = useState(defaultConfirmModalContent);


  //buttons
  const [isSendingInvite, setIsSendingInvite] = useState(false);
  const [
    displayButtonContainerOnMobile,
    setDisplayButtonContainerOnMobile
  ] = useState(false);

  const handleInvite = () => {
    let selectedProjects = projectList.filter((p:ProjectListItemProps) => {
      return p.selected;
    });

    let sanitizedProjects = selectedProjects.map((p: any) => {
      return {
        premiseId: p.premiseID,
        userRole: p.userRole,
        projectAddress: p.projectAddress
      };
    });

    const delegate = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      title: "",
      phone: phone,
      projects: sanitizedProjects
    };


    setIsSendingInvite(true);
    bpID && axios
      .post(
        // `${getDomain()}
        `${getDomain()}api/construction/usersManagement/v1/users/businessID/${bpID}`,
        { users: [delegate] },
        { withCredentials: true }
      )
      .then(r => {
        // success message
        setConfirmModalContent(
          <>
            <h2>Invite Sent</h2>
            <div className="invite-new-contact-modal__explanation-paragraph">
              Your contact should receive a confirmation email shortly.
            </div>
            <ButtonPrimary
              className="invite-new-contact-modal__save-btn"
              onClick={() => {
                setModalOpen(false);
                setConfirmModalContent(defaultConfirmModalContent);
                setState(initialState);
                setIsSendingInvite(false);
                // resetInputs()
              }}
            >
              Close
            </ButtonPrimary>
          </>
        );
        setCanceled(true);
        setModalToBeScrolled(true);
      })
      .catch(e => {
        setConfirmModalContent(
          <>
            <h2>Error sending invite.</h2>
            <div className="invite-new-contact-modal__explanation-paragraph border-bottom">
              Please try again later.
            </div>
            <ButtonPrimary
              className="invite-new-contact-modal__save-btn"
              onClick={() => {
                setModalOpen(false);
                setConfirmModalContent(defaultConfirmModalContent);
                setState(initialState);
                setIsSendingInvite(false);
              }}
            >
              Close
            </ButtonPrimary>
          </>
        );
        setCanceled(true);
        setModalToBeScrolled(true);
      });
  };
  const modalButtons = (
    <div
      className={`invite-new-contact-modal__button-container${
        displayButtonContainerOnMobile ? " changes-made" : ""
        }`}
    >
      <ButtonPrimary
        className="invite-new-contact-modal__button-container-save-btn"
        onClick={() => {
          handleInvite();
        }}
        disabled={!isFormReady}
        dataTrack="CMC"
        dataTrackDetail="Invite Contact"
        dataTrackAction="click"
        dataTrackSubAction="submit"
      >
        <FontAwesomeIcon
          style={{
            opacity: isSendingInvite ? 1 : 0,
            display: isSendingInvite ? "inline-block" : "none"
          }}
          icon={faSpinner}
          spin
        />
        &nbsp;Submit
      </ButtonPrimary>
      <ButtonPrimary
        className="invite-new-contact-modal__button-container-cancel-btn"
        onClick={() => {
          if (atLeastOneProjectSelected || atLeastOneChangeMade) {
            setCanceled(true);
            setModalToBeScrolled(true);
          } else {
            setModalOpen(false);
          }
        }}
      >
        Cancel
      </ButtonPrimary>
    </div>
  );

  return (
    <div className="invite-new-contact-modal">
      <div className={canceled ? "canceled" : "not-canceled"}>
        {confirmModalContent}
      </div>
      <div className={`invite-new-contact-modal__contents ${canceled ? "canceled-overlay" : ""}`}>
        <h4>Contact Details</h4>
        <div className="invite-new-contact-modal__explanation-paragraph">
          Please fill out the information below to invite a new contact. *Required
          Field
      </div>
        {inviteNewContactsForm}
        {showMoreList}
        <br />
        {modalButtons}
      </div>
    </div>
  );
};

export default InviteContactsModalContent1;
