import React, { useContext } from 'react';
import Modal from '../Modal/Modal';
import ButtonPrimary from '../ButtonPrimary/ButtonPrimary';
import { ModalContext } from '../../../contexts/modalContext';

interface ClickToOpenModalProps {
  title: string;
  text: string;
  type: 'button' | 'link';
  className?: string;
  dataTrack?: string;
  dataTrackDetail?: string;
  dataTrackAction?: string;
  dataTrackSubAction?: string;
  disabled?: boolean;
}

const ClickToOpenModal: React.FunctionComponent<React.PropsWithChildren<
  ClickToOpenModalProps
>> = (
  props: React.PropsWithChildren<ClickToOpenModalProps>
): React.ReactElement => {
  const { modalOpen, setModalOpen } = useContext(ModalContext);

  const closeModalHandler = (event: React.SyntheticEvent): void => {
    const eventTarget = event.target as Element;

    const backgroundOrCloseButtonClicked =
      eventTarget.classList.contains('modal') ||
      eventTarget.classList.contains('modal__close-button');

    if (backgroundOrCloseButtonClicked) {
      setModalOpen(false);
    }
  };

  const openModalHandler = (): void => {
    if (window.screen.width <= 767) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
    }

    setModalOpen(true);
  };

  const keyPressHandler = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      openModalHandler();
    }
  };

  let interactiveElement = null;

  if (props.type === 'button') {
    interactiveElement = (
      <ButtonPrimary
        className={props.className ? props.className : ''}
        onClick={openModalHandler}
        dataTrack={props.dataTrack}
        dataTrackDetail={props.dataTrackDetail}
        dataTrackAction={props.dataTrackAction}
        dataTrackSubAction={props.dataTrackSubAction}
        // disabled = {true}
        disabled={
          props.disabled ||
          (props.dataTrackDetail === 'Invite Contact' &&
            (localStorage.getItem('cmcElevatedRole') === 'View' ||
              localStorage.getItem('cmcElevatedRole') === 'Edit'))
        }
      >
        {props.text}
      </ButtonPrimary>
    );
  } else {
    interactiveElement = (
      // We are disabling the jsx-a11y/anchor-is-valid rule because the component should
      // be styled as a link and it behaves as expected with the keyboard
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        className={`link-to-modal${
          props.className ? ` ${props.className}` : ''
        }`}
        style={{fontWeight:600}}
        role="button"
        tabIndex={0}
        aria-label="link_to_modal"
        onKeyPress={keyPressHandler}
        onClick={openModalHandler}
      >
        {props.text}
      </a>
    );
  }

  return (
    <React.Fragment>
      {interactiveElement}
      <Modal
        className="modal"
        open={modalOpen}
        closeHandler={(event: React.SyntheticEvent) => closeModalHandler(event)}
        title={props.title}
      >
        {props.children}
      </Modal>
    </React.Fragment>
  );
};

export default ClickToOpenModal;
