import React from "react";
import NumberFormat from "react-number-format";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "../../common/Card/Card";
import Tooltip from "../../common/Tooltip/Tooltip"
import "./AccountManagerCard.scss";

interface Contact {
    name: string;
    phone?: string;
    email?: string;
    fax?: string;
    address?: string;
    title?: string;
}
interface AccountProps {
    managerData: Contact;
    regionalData?: Contact;
    hasGas: boolean;
}

const AccountManagerCard: React.FunctionComponent<AccountProps> = ({
    managerData = {
      name: "",
      phone: "",
      email: ""
    },
    hasGas = false,
    regionalData
  }): React.ReactElement => {
    const toolTipText = `Your DTE account manager is here to assist you from start to finish.  Please contact your account manager with any questions, concerns, or to discuss upcoming construction projects.`
   
    const accountManagerCard = (contact: Contact) => {
        return (
             <div className="user-details">
                <div>
                    <h4>{contact.title ? contact.title : 
                    <>DTE Electric Account Manager&nbsp;
                        <Tooltip tooltipBody={toolTipText}>
                            <FontAwesomeIcon
                                icon={faInfoCircle}
                            />
                        </Tooltip></>}
                    </h4>
                    <strong>{contact.name.toLowerCase()}</strong>

                </div>
                <div className="address">
                    {contact.phone && <NumberFormat
                        name        = "phoneValue"
                        type        = "tel"
                        format      = "###.###.####"
                        placeholder = "(   )   -"
                        value       = {contact.phone}
                        displayType = "text"
                    />}
                   {contact.email && <div><a href={`mailto:${contact.email.toLowerCase() || " "}`}>{contact.email.toLowerCase() || " "}</a></div>}
                </div>

                {/* TO-DO move contact-info code below address.div when address data comes in */}
            </div>
        )
    }


    return (
        <Card className="account-manager-card">
          {managerData.name && accountManagerCard(managerData)}
          {regionalData && accountManagerCard(regionalData)}
          {hasGas && 
          <div className="dte-account-manager">
              <h4>DTE Gas representative</h4>
              <p>
                  For questions regarding your natural gas projects, please contact
                  your DTE representative.  Contact information can be found
                  within steps 2, 3, and 7 of each work order.
              </p>
          </div>}
          
      </Card>
  );
};

export default AccountManagerCard;