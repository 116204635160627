import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  ChangeEvent
} from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import getDomain from "../../../services/getDomain";
import { getActiveBPID } from "../../../services/getAccountData";
import { handleErrorMessage, paths } from "../../../services/useAPI";
import { getCookie } from "../../../utilities/cookieUtils";
import { cacheHandler } from "../../../utilities/cacheUtils";
import { UserSessionContext } from "../../../contexts/userSessionContext";
import { LogInResponse } from "../../../types/logInResponse";
import Input from "../../common/Input/Input"
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { useGlobalState } from "../../App/App"

import "./LogInContents.scss";

const LogInContents = (
  props: RouteComponentProps & {dataTrackDetail?: string; setGuestSearchWON?: any; handleStaticModal?: (val: boolean) => void; }
  ): React.ReactElement => {
  const { setUserSession } = useContext(UserSessionContext);
  const emailInputRef      = useRef<HTMLInputElement>(null);
  const passwordInputRef   = useRef<HTMLInputElement>(null);

  const [buttonDisabled, setButtonDisabled]                   = useState(true);
  const [emailValue, setEmailValue]                           = useState("");
  const [passwordValue, setPasswordValue]                     = useState("");
  const [authenticationErrorText, setauthenticationErrorText] = useState("");
  const [guestSearchActive, setGuestSearchActive] = useState(false);
  const [isSafari] = useState(navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') === -1 && navigator.userAgent.indexOf('FxiOS') === -1)

  const previousHistory = useGlobalState('previousHistory');

  // const [errorMessage, setErrorMessage] = useState("")

  const [errorMessage, setErrorMessage] = useState("")

  const [loginLoading, setLoginLoading] = useState(false);
  // const [unconfirmedEmail, setUnconfirmedEmail] = useState(false)

  // const { data, loading, errorMessage, trigger } = useAPI(
  //   paths.signIn,
  //   "post",
  //   { username: emailValue, password: passwordValue }
  // );

  // This hook is used to validate the form and disable/enable the sign in button
  useEffect((): void => {
    if (!emailInputRef.current) {
      throw new Error(
        `LogInContents.tsx: emailInputRef is of type ${typeof emailInputRef}`
      );
    }

    if (!loginLoading) {
      setButtonDisabled(false);
      if (errorMessage && authenticationErrorText !== errorMessage) {
        setauthenticationErrorText(errorMessage);
        setEmailAndPasswordErrorState();
      }
    } else {
      setButtonDisabled(true);
      // setauthenticationErrorText("");
      // setPasswordValue("");
    }
  }, [emailValue, passwordValue, emailInputRef, loginLoading]);

  const setEmailAndPasswordErrorState = () => {
    if (emailInputRef.current) {
      emailInputRef.current.setCustomValidity("error");
    }

    if (passwordInputRef.current) {
      passwordInputRef.current.setCustomValidity("error");
    }
  };

  const signInClickHandler = async (): Promise<void> => {
    if (emailValue && passwordValue) {
      // trigger();
      setLoginLoading(true)
      props.handleStaticModal && props.handleStaticModal(false)

      const userData = await axios.post(paths.signIn,
        { username: emailValue, password: passwordValue },
        { withCredentials: true }
      ).then(r => r.data).catch(e => {
        if(e && e.response && e.response.data.errorCode === "403.Forbidden.InactiveLogin") {
          // setUnconfirmedEmail(true)
          cacheHandler.set('staticModalText', `You have not verified your email address yet. Would you like us to send another online account confirmation email to <strong>${emailValue}</strong>`)
          cacheHandler.set('staticModalTitle', 'Resend Email')
          cacheHandler.set('staticModalButtonText', 'Send Email')
          props.handleStaticModal && props.handleStaticModal(true)
        } else {
          if (e && e.response && e.response.data.errorCode === '403.Forbidden.AccountLockedExists') {
            e.response.data.errorMessage = e.response.data.errorMessage.replace(
              '/wps/wcm/connect/dte-web/quicklinks/reset-password',
              `${getDomain()}wps/wcm/connect/dte-web/quicklinks/reset-password`
            )
          }
          setErrorMessage(handleErrorMessage(e))
          console.log(e.response)
        }
        setLoginLoading(false)
      });

      if (userData as LogInResponse) {
          if (isSafari) {
            console.log('isSafari')
            if (!getCookie('safariValidated') && window.location.hostname === "precmc.dteenergy.com") {
              localStorage.setItem('safariValidated', 'true')
              document.cookie = `safariValidated=true; max-age=90;`;
              setErrorMessage(`You are using Safari on Precmc.  Please manually set a dteco.com cookie <a href="${getDomain()}wps/wcm/connect/dte-web-apps/hidden-pages/cmc_test?webSecurityToken=${userData.accessToken.webSecurityToken}">here</a>`)
              setLoginLoading(false)
              // window.location.href=`${getDomain()}wps/wcm/connect/dte-web-apps/hidden-pages/cmc_test?webSecurityToken=${userData.accessToken.webSecurityToken}`;
            }
          } else {
            console.log('not safari')
          }

        let actingBPID = await getActiveBPID(userData.user.uid) || userData.uid;
        const profileObj =
          await axios.get(`${getDomain()}api/construction/usersManagement/v1/users/userProfile/businessID/${actingBPID}`,
          { withCredentials: true })
          .then(r => r.data).catch(e => {
            setErrorMessage(handleErrorMessage(e) || "Sorry, you are not a construction customer.")
            setLoginLoading(false)
          });
        if (profileObj) {
          // { "userProfile":{ "isConstructionAccount":"true", "numberOfActiveConstructionAccounts":"1" } }
          // var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
          // navigator.userAgent &&
          // navigator.userAgent.indexOf('CriOS') == -1 &&
          // navigator.userAgent.indexOf('FxiOS') == -1;
          // alert(isSafari)

          if (profileObj.userProfile.isConstructionAccount && profileObj.userProfile.isConstructionAccount === "false") {
            setErrorMessage("Sorry, you are not a construction customer.")
            // setIsErrorModalOpen(true)
            cacheHandler.set('staticModalText', `You currently have no active projects for this account. If you would like to submit a new request for service, please call 800.338.0178. If you are trying to access your DTE Energy account, please <a href="${getDomain()}wps/wcm/connect/dte-web/login/"> sign in.</a>`)
            cacheHandler.set('staticModalTitle', 'No Active Projects')
            cacheHandler.set('staticModalButtonText', 'Go Back')
            props.handleStaticModal && props.handleStaticModal(true)

            setErrorMessage("")
            setLoginLoading(false)
          }
          if (profileObj.userProfile.isConstructionAccount && profileObj.userProfile.isConstructionAccount === "true") {
            setUserSession(userData)
            // localStorage.set('isConstructionAccount', 'true')
            // if (isSafari) {
            //   // Safari will not honor the Set-Cookie header in the signIn response
            //   // And it tries to block iFrame embedding
            //   // And web-qa's CSP tries to block iFrame embedding
            //   // So...
            //   window.location.href=`${getDomain()}wps/wcm/connect/dte-web-apps/hidden-pages/cmc_test?webSecurityToken=${userData.accessToken.webSecurityToken}&redirect=true`;
            // }
            if(previousHistory) {
              window.location.pathname !== "/" && props.history.push(previousHistory)
            } else {
              window.location.pathname !== "/" && props.history.push('/')
            }
            setLoginLoading(false)
          }
          if (!profileObj.userProfile || !profileObj.userProfile.isConstructionAccount) {
            setErrorMessage("Sorry, we could not validate your credentials.")
            setLoginLoading(false)
          }
        } else {
          setErrorMessage("Sorry, you are not a construction customer.")
          setLoginLoading(false)
        }
      } else {
        // error: no account in general system
      }

    }
  };

  const inputEnterKeyPressHandler = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (!buttonDisabled && event.charCode === 13) {
      signInClickHandler();
    }
  };

  // This event handler allows us to display the "required" error message only
  // once the email input has lost focus
  const emailBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    if(!event.target.value.length) {
      event.target.removeAttribute("required")
    } else {
      event.target.setAttribute("required", "true");
    }
  };

  const emailChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (emailInputRef.current) {
      emailInputRef.current.setCustomValidity("");
    }

    setEmailValue(event.target.value);
  };

  const passwordChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (passwordInputRef.current) {
      passwordInputRef.current.setCustomValidity("");
    }

    setPasswordValue(event.target.value);
  };

  const activateGuestSearch = () => {
    window.history.replaceState(null, "", window.location.pathname);
    setGuestSearchActive(true);
  }

  const problemsSigningInText = 'Online access to your DTE Energy account has been locked due to multiple failed login attempts. To unlock your account, please use the Reset Password link sent to your email address. <br><br>You can also click on "Problems Signing In" to begin resetting your password.';
  const problemsSigningInTextReplacement = `Online access to your DTE Energy account has been locked due to multiple failed login attempts. To unlock your account, please use the <a href="${getDomain()}wps/wcm/connect/dte-web/quicklinks/reset-password" target="_blank">Reset Password</a> link sent to your email address.`;

  const formContent = (
    <>
      <input
        type         = "email"
        name         = "email"
        placeholder  = "Email"
        className    = "tab-to-trigger"
        value        = {emailValue}
        onChange     = {(event): void => emailChangeHandler(event)}
        ref          = {emailInputRef}
        onKeyPress   = {inputEnterKeyPressHandler}
        onBlur       = {emailBlurHandler}
        autoComplete = "on"
      />
      {/* We don't want to display the email error text if the authentication error is displayed already,
          so we toggle the email-error class, which makes this error visible */}
      <div
        className={`error ${
          !!emailValue.includes("@") && emailValue[emailValue.length - 1] !== "@"  ? "" : "email-error"
        }`}
        data-testid="email-error"
      >
        Please use a valid email address for your User ID (ex: name@email.com)
      </div>
      <input
        type         = "password"
        name         = "password"
        placeholder  = "Password"
        className    = "tab-to-trigger"
        value        = {passwordValue}
        onChange     = {(event): void => passwordChangeHandler(event)}
        ref          = {passwordInputRef}
        onKeyPress   = {inputEnterKeyPressHandler}
        autoComplete = "on"
      />
      <div
        className={`error authentication-error${
          authenticationErrorText.length ? " invalid" : ""
        }`}
        style={{wordWrap:"break-word"}}
        data-testid="authentication-error"
        // We need to use this dangerous function to properly format the error messages
        // This is safe because they come from the server
        // eslint-disable-next-line react/no-danger

        dangerouslySetInnerHTML={authenticationErrorText === problemsSigningInText
           ? { __html: problemsSigningInTextReplacement }
           : { __html: authenticationErrorText }}
      />

      <Input
        label="Remember Me"
        labelRight
        type="checkbox"
        name="rememberme"
        autoComplete="on"
        onClick={() => {}}
      />

      <ButtonPrimary
        className          = "btn-log-in"
        onClick            = {signInClickHandler}
        disabled           = {buttonDisabled}
        dataTrack          = "SIGNIN"
        dataTrackDetail    = {props.dataTrackDetail ? props.dataTrackDetail : "CMC Home Page"}
        dataTrackAction    = "Sign In"
        dataTrackSubAction = "Customer SignIn"
      >
        <FontAwesomeIcon
          className={
            loginLoading
              ? "log-in-contents--loading-spinner-loading"
              : "log-in-contents--loading-spinner"
          }
          icon={faSpinner}
          spin
        />
        Sign In{" "}
      </ButtonPrimary>
      {/* <button onClick={e => activateGuestSearch()}>Guest Search</button> */}

      <div className="link-container">
        <a
          href={`${getDomain()}wps/wcm/connect/dte-web/quicklinks/reset-password`}
        >
          Forgot your password?
        </a>
        {/*<a href={`${getDomain()}commercial/registration/index.jsp`}>Register</a>*/}
      </div>

      { !props.dataTrackDetail &&
        <div className="link-container" style={{textAlign:"left", marginTop:"2rem"}}>
          <a tabIndex={0} href={`${
            window.location.hostname === "cmc.dteenergy.com" ? "https://cmc.customer.sites.dteenergy.com" : "https://nonprod.cmc.customer.sites.dteenergy.com"
          }`} aria-label="guest search">Employee Sign-In</a>
        </div>}
    </>
  );

  const [WON, setWON] = useState("");

  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [WONErrorMessage, setWONErrorMessage] = useState("");

  const getSiteKey = () => {
    if (window.location.hostname === "localhost") {
      return "6LfZbIgUAAAAAHTQPKcAP_93c2WdCalJrsDPrtLU";
    } else if (
      window.location.hostname === "testcmc.dteenergy.com" ||
      window.location.hostname === "precmc.dteenergy.com"
    ) {
      return "6LfKESMTAAAAANcRYPMder7Lkur0vpuDmQWj0Fwa";
    } else if (window.location.hostname === "cmc.dteenergy.com") {
      return "6LcFFCMTAAAAAAVybGZ_bkuzhptvub8-sCcm6w23";
    } else {
      return ""
    }
  }
  const [sitekeyByDomain, setSiteKeyByDomain] = useState(getSiteKey());

  const handleGuestSearch = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (WON) {
      if (WON.length === 8) {
        props.setGuestSearchWON(WON)
        // localStorage.setItem('cmcRecaptcha', recaptchaData);
        setWONErrorMessage("")
        props.history.push(`/guest-search/${WON}`, {rd: recaptchaData});
      } else {
        setWONErrorMessage("Your work order number must contain 8 digits")
      }
    }
  }

  const [recaptchaData, setRecaptchaData] = useState({ rd: "rd"});
  const handleRecaptcha = (data: any) => {
    setRecaptchaData(data)
    setRecaptchaValid(true)
  }

  const validateWon = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^\d-]/g, '');
    if (e.target.value.length > 8) {
      e.target.value = e.target.value.substr(0, 8);
    }
    if (e.target.value.length === 8) {
      setWON(e.target.value)
    }
    setWON(e.target.value)

  }
  const guestSearchForm = (
    <>
      <div style={{marginBottom:"1rem"}}>
        <span>Guest Search</span>
      </div>
      <input type="text" placeholder="8-Digit Work Order Number" onChange={e => validateWon(e)} />
      {WONErrorMessage && <div style={{color:"#e31937",paddingBottom:".5rem"}}>{WONErrorMessage}</div>}
      <div className="recaptcha">
        <ErrorBoundary
          msg="ReCAPTCHA is currently unavailable"
          >
          <ReCAPTCHA
            sitekey={sitekeyByDomain}
            onChange={handleRecaptcha}
            size="normal"
          />
        </ErrorBoundary>
      </div>
      <button disabled={!recaptchaValid} style={{width:"100%"}} onClick={e => handleGuestSearch(e)}>Search</button>
      <div>
        <button className="link-button" onClick={e => setGuestSearchActive(false)}>
          <FontAwesomeIcon
              className="chevron-left"
              icon={faChevronLeft}
          />
          Sign In</button>
        {/*<a style={{float:"right"}} href={`${getDomain()}commercial/registration/index.jsp`}>Register</a>*/}
      </div>
    </>
  )

  return (
    <form className="log-in-contents">
      { guestSearchActive ? guestSearchForm : formContent}
    </form>
  );

};

export default withRouter(LogInContents);
