import React, { useState, useEffect } from "react";
import axios from "axios";
import { Prompt } from "react-router-dom";
import NumberFormat from "react-number-format";
import { fireEvent } from "@testing-library/react";
import { ProjectsList } from "../../../../types/ProjectsList";
import getDomain from "../../../../services/getDomain";
import { titleCase } from "../../../../utilities/utilities";
import { cacheHandler } from "../../../../utilities/cacheUtils";
import Pill from "../../../common/Pill/Pill";
import Accordion from "../../../common/Accordion/Acordion";
import ButtonPrimary from "../../../common/ButtonPrimary/ButtonPrimary";
import { getDelegatesResponse } from "../../../../types/getDelegatesResponse";
import AddToProjectButton from "../../AddToProjectButton/AddToProjectButton";
import ManageContactsProjectsListItem from "./ManageContactsProjectListitem/ManageContactsProjectsListItem";
import "./ManageContactsListItem.scss";
import Tooltip from "../../../common/Tooltip/Tooltip";

const ManageContactsListItem = (props: getDelegatesResponse) => {
  const [projects, setProjects] = useState(props.projects);
  const [editedProjects, setEditedProjects] = useState(props.projects);
  const [addedProjects, setAddedProjects] = useState([{ premiseID: "" }]);
  const [hasOneChange, setHasOneChange] = useState(false);
  const [removeAllSelected, setRemoveAllSelected] = useState(false);
  const [addedProjectsStyling, setAddedProjectsStyling] = useState([{ premiseID: "" }])
  const [saveResponseStatus, setSaveResponseStatus] = useState({text:"", style:""});
  const [resendInviteStatus, setResendInviteStatus] = useState({text:"Resend Invitation", style:""})
  const loggedInUserProjectsList: ProjectsList[] = JSON.parse(localStorage.getItem('cmcProjectsList') || "[]");
  const ownerBPID = cacheHandler.get('activeBPID') || ""
  const elevatedRole = cacheHandler.get('elevatedRole') || "View";
  console.log("ManageContactsListItem -> elevatedRole", elevatedRole)

  const setProjectAccessLevel = (id: string, accessLevel: any) => {
    let updatedProjects = [...projects];
    updatedProjects = updatedProjects.map(project => {
      if (project.premiseID === id) {
        const updatedProject = { ...project };
        updatedProject.userRole = accessLevel;
        setHasOneChange(true)
        return {
          premiseID: updatedProject.premiseID,
          userRole: updatedProject.userRole,
          projectAddress: updatedProject.projectAddress,
          operation: updatedProject.operation === "A" ? "A" : "M"
        }
      }
      return project;
    });
    setProjects(updatedProjects);
    setEditedProjects(updatedProjects);
  };

  const getLoggedInUserRoleOnProject = (premiseID: string = "") => {
    const projectInQuestion = loggedInUserProjectsList.find((p: any) => {
      return p.premiseID === premiseID
    })
    return projectInQuestion ? projectInQuestion.userRole : "View"
  }

  const removeAllFromProject = (event: React.ChangeEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    let parent: HTMLElement | any = event.target
    while (parent.className !== 'heading') {
      parent = parent.parentElement;
    }

    const elements = parent.parentElement.getElementsByClassName(`manage-contacts-list-item__projects-list__item__`+
    `${removeAllSelected ? "cancel" : "remove"}-button`)
    for (let element of elements) {
      element.click();
    }

    setRemoveAllSelected(!removeAllSelected)
  }

  const removeFromProject = (id: string, event: React.ChangeEvent<HTMLButtonElement>) => {
    if (event.target.parentElement) {
      if (event.target.parentElement.classList.contains("recent-add")) {
        event.target.parentElement.classList.remove("recent-add");
      }
      event.target.parentElement.classList.add("remove");
    }
    let updatedProjects = editedProjects.filter(project => {
      return project.premiseID !== id;
    })
    setEditedProjects(updatedProjects);
    setHasOneChange(true)
  }

  const removeMarkFromProject = (id: string) => {
    let updatedProject = projects.filter(project => {
      return project.premiseID === id;
    })
    setEditedProjects(projects.concat(updatedProject));
    let tempArray = document.querySelectorAll('div.remove');
    if (tempArray.length === 0) {
      setHasOneChange(false);
    }
  }

  const resetCss = () => {
    let tempArray = document.querySelectorAll('div.remove');
    tempArray.forEach(element => {
      const btnElement = element.children.namedItem("remove-button") as HTMLButtonElement
      fireEvent.click(btnElement);
    })
  }

  // may cause issues down the road, or not
  useEffect((): void => {
    if (addedProjects[0].premiseID.length) {
      requestSimpleSave();
      setAddedProjects([{ premiseID: "" }]);
    }
  }, [addedProjects]);
  // ^ addedProjects should only tigger for addingToProject

  const addToProject = (addedProjects: any) => {
    setProjects(projects.concat(addedProjects));
    setEditedProjects(projects.concat(addedProjects));
    setAddedProjects(addedProjects);
    setAddedProjectsStyling(addedProjects);
    setHasOneChange(false);
  }

  const getEligibleProjects = () => {
    const addedProjectsDifference = loggedInUserProjectsList.filter(({ premiseID: id1 }) => !addedProjects.some(({ premiseID: id2 }) => id2 === id1));

    const difference = addedProjectsDifference.filter(({ premiseID: id1 }) => !editedProjects.some(({ premiseID: id2 }) => id2 === id1));

    const permitted = difference.filter(p => {
      return p.userRole !== "View" && p.userRole !== "Edit"
    })

    const differenceWithDefaultRoles = permitted.map(d => {
      d.userRole = "View"
      return d
    })
    return differenceWithDefaultRoles;
  }

  const [eligibleProjects, setEligibleProjects] = useState(getEligibleProjects);

  useEffect((): void => { }, [eligibleProjects]);

  const requestSimpleSave = () => {
    const oldProjects = props.projects;
    const newProjects = editedProjects;

    const removedProjects = oldProjects.filter(({ premiseID: id1 }) => !newProjects.some(({ premiseID: id2 }) => id2 === id1));

    const removedProjectsSanitized = removedProjects.map(p => {
      return {
        premiseId: p.premiseID,
        projectAddress: p.projectAddress,
        userRole: p.userRole,
        projectID: p.projectID || "",
        operation: "R"
      }
    })
    const changedProjects = projects.filter(p => p.operation)
    const changedProjectsSanitized = changedProjects.map(p => {
      return {
        premiseId: p.premiseID,
        projectAddress: p.projectAddress,
        userRole: p.userRole,
        projectID: p.projectID || "",
        operation: p.operation
      }
    })
    const allProjectsToSend = changedProjectsSanitized.concat(removedProjectsSanitized)
    const user = {
      firstName: props.firstName,
      lastName: props.lastName,
      id: props.id,
      email: props.email,
      phone: "",
      projects: allProjectsToSend
    }
    const body = {
      users: [user]
    }

    setProjects(editedProjects);
    setEligibleProjects(getEligibleProjects());
    if (hasOneChange) {
      setHasOneChange(false);
    }

    axios.put(`${getDomain()}api/construction/usersManagement/v1/users/businessId/${ownerBPID}`, body, { withCredentials: true })
      .then(r => {
        setSaveResponseStatus({text:"Your changes have been successfully saved.", style: "success"})
        setTimeout(() => {
          setSaveResponseStatus({text:"", style: ""});
        }, 5000)
      }).catch(e => {
        setSaveResponseStatus({text:"Sorry, the request could not be processed.", style: ""})
        setTimeout(() => {
          setSaveResponseStatus({text:"", style: ""});
        }, 5000)
      })
  }

  const showDropdown = (event:any) => {
    const editButton:HTMLElement = event.target;
    const dropdown = document.getElementById("edit-dropdown"+props.id)
    if (!editButton || !dropdown) {
      return null
    }
    const accordion = editButton.closest(".accordion");
    if (accordion && accordion.classList.contains("open")) {
      dropdown.classList.toggle("show");
      event.stopPropagation();
    }
  }
  //this hides our Edit/Remove All dropdown if clicked outside
  window.onclick = function(event:any) {
    if (!event.target.matches('.dropdown-button')) {
      let dropdowns = document.getElementsByClassName("manage-contacts-list-item__remove-all-container__dropdown");
      let i;
      for (i = 0; i < dropdowns.length; i++) {
        let openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }

  const resendInvite = (e:any) => {
    e.stopPropagation();
    let sanitizedProjects = props.projects.map((p: any) => {
      return {
        premiseId: p.premiseID,
        userRole: p.userRole,
        projectAddress: p.projectAddress
      };
    });
    const delegate = {
      firstName: props.firstName,
      lastName: props.lastName,
      email: props.email,
      title: "",
      phone: props.phone,
      projects: sanitizedProjects
    }
    axios
      .post(
        `${getDomain()}api/construction/usersManagement/v1/users/businessID/${ownerBPID}`,
        { users: [delegate] },
        { withCredentials: true }
      )
      .then(r => {
        setResendInviteStatus({text:"Successfully Sent", style: "success"})
        setTimeout(() => {
          setResendInviteStatus({text:"Resend Invitation", style: ""});
        }, 5000)
      })
      .catch(e => {
        setResendInviteStatus({text:"Error Sending", style: "failure"})
        setTimeout(() => {
          setResendInviteStatus({text:"Resend Invitation", style: ""});
        }, 5000)
      });
  }


  const heading = (
    <>
      <div className="manage-contacts-list-item__name">
        {titleCase(props.firstName + ' ' + props.lastName)}
      </div>
      <div className="manage-contacts-list-item__email">
        <a href={`mailto:${props.email.toLowerCase() || " "}`}>{props.email.toLowerCase() || " "}</a>
      </div>
      <div className="manage-contacts-list-item__pills-wrapper">
            <Pill>{props.status}</Pill>
        </div>
      <div className="manage-contacts-list-item__phone">
        <NumberFormat
          name="phoneValue"
          type="tel"
          format="(###) ###-####"
          placeholder="(   )   -"
          value={props.phone}
          displayType="text"
        />
      </div>
      {props.status.toLowerCase() !== "expired"
        ?
        <div className="manage-contacts-list-item__remove-all-container">

          {elevatedRole !== "View" && elevatedRole !== "Edit" ?
          <button onClick={(e) => showDropdown(e)} className="dropdown-button tab-to-trigger">Edit</button> :
         <Tooltip tooltipBody="Your access level prevents modification of this project">
          Edit
         </Tooltip>
         }

          <div id={"edit-dropdown"+props.id} className="manage-contacts-list-item__remove-all-container__dropdown">
            <button className="link-button" onClick={(e: any) => removeAllFromProject(e)}>
              {!removeAllSelected ? "Remove All" : "Cancel"}
            </button>
          </div>
        </div>
        :
        <div className="manage-contacts-list-item__resend-invite">
         {elevatedRole !== "View" && elevatedRole !== "Edit" ?
         <button onClick={(e) => resendInvite(e)} className={"link-button " + resendInviteStatus.style} disabled>
          {resendInviteStatus.text}
         </button> :
         <Tooltip tooltipBody="Your access level prevents modification of this project">
          Resend Invitation
         </Tooltip>
         }
        </div>
      }
    </>
  );

  const projectsContent = projects.map(project => (
        <ManageContactsProjectsListItem
          key={project.premiseID}
          premiseID={project.premiseID}
          projectName={project.projectName}
          projectAddress={project.projectAddress}
          userRole={project.userRole}
          changeHandler={setProjectAccessLevel}
          removeMarkFromProject={removeMarkFromProject}
          removeFromProject={removeFromProject}
          loggedInUserRole={getLoggedInUserRoleOnProject(project.premiseID)}
          className={addedProjectsStyling.some(addedProject => {
            return addedProject.premiseID === project.premiseID;
          }) ? "recent-add" : ""}
        />
      )
  );

  const buttonsContent = (
    <>
      <ButtonPrimary
        className="manage-contacts-list-item__button-container__save"
        onClick={() => {
          requestSimpleSave()
        }}
        disabled={!hasOneChange}
      >
        Save
      </ButtonPrimary>

      <ButtonPrimary
        className="manage-contacts-list-item__button-container__cancel"
        onClick={() => {
          resetCss();
          setProjects(props.projects);
          setEditedProjects(props.projects);
          setEligibleProjects(getEligibleProjects())
          setHasOneChange(false);
          setRemoveAllSelected(false)
        }}
      >
        Cancel
      </ButtonPrimary>

      <AddToProjectButton
        className="manage-contacts-list-item__button-container__invite"
        addToProject={addToProject}
        projects={eligibleProjects}
        disabled={
          (elevatedRole === "View" || elevatedRole === "Edit")
          || hasOneChange
        }
      />

      {<div
      className={`manage-contacts-list-item__button-container__message ${saveResponseStatus.style}`}>
        {saveResponseStatus.text}
      </div>}
    </>
  )


  return (
    projects &&
    <div className="manage-contacts-list-item not-ready-for-show">
      <Prompt
        when={hasOneChange}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
      <Accordion heading={heading} includeChevron>
        <div className="manage-contacts-list-item__projects-container">
        {projectsContent}
        </div>

        <div className="manage-contacts-list-item__button-container">
        {buttonsContent}
        </div>
      </Accordion>
    </div>
  );
};

export default ManageContactsListItem;
