import React from "react";
import ClickToOpenModal from "../ClickToOpenModal/ClickToOpenModal";
import { ModalContextProvider } from "../../../contexts/modalContext";

interface ButtonToModalProps {
  title              : string;
  linkText           : string;
  children           : string | JSX.Element | JSX.Element[];
  className?         : string;
  dataTrack?         : string;
  dataTrackDetail?   : string;
  dataTrackAction?   : string;
  dataTrackSubAction?: string;
  disabled?           : boolean;
}

const ButtonToModal: React.FunctionComponent<ButtonToModalProps> = (
  props: ButtonToModalProps
): React.ReactElement => {
  return (
    <ModalContextProvider>
      <ClickToOpenModal
        className          = {props.className}
        title              = {props.title}
        type               = "button"
        text               = {props.linkText}
        dataTrack          = {props.dataTrack}
        dataTrackDetail    = {props.dataTrackDetail}
        dataTrackAction    = {props.dataTrackAction}
        dataTrackSubAction = {props.dataTrackSubAction}
        disabled           = {props.disabled}
      >
        {props.children}
      </ClickToOpenModal>
    </ModalContextProvider>
  );
};

export default ButtonToModal;
