import React from "react";

const DTEIcon = (): React.ReactElement => {
  return (
    <svg
      fill="#FFF"
      className="logo"
      id="dte-icon"
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      viewBox="0 0 93.362 32"
    >
      <path
        className="dte-logo-svg"
        fill="#FFF"
        fillRule="nonzero"
        d="M 62.384,7.787 H 53.412 V 32 H 41.765 V 7.787 h -8.99 V 0.018 h 29.61 v 7.77 z M 93.224,7.662 V 0 H 66.823 V 24.313 A 7.598,7.598 0 0 0 74.42,31.91 H 93.362 V 24.213 H 79.809 c -0.98,0 -1.777,-0.797 -1.777,-1.778 v -3.159 h 14.583 v -7.09 H 78.032 V 7.662 H 93.226 Z M 19.719,31.975 H 0 V 0.05 h 19.653 a 9.403,9.403 0 0 1 9.403,9.403 v 13.184 a 9.338,9.338 0 0 1 -9.338,9.338 z M 18.289,21.883 V 10.006 A 2.45,2.45 0 0 0 15.841,7.557 h -4.312 v 16.774 h 4.312 a 2.45,2.45 0 0 0 2.449,-2.448 z"
      />
    </svg>
  );
};

export default DTEIcon;
