import React from "react";
import ErrorPage from "../ErrorPage";
import ButtonPrimary from "../../common/ButtonPrimary/ButtonPrimary";

const AccessDeniedCard: React.FunctionComponent = (): React.ReactElement => {
  return (
    <ErrorPage>
      <h2>Access Denied</h2>
      <p>Your access level does not allow you to view this information.  Please contact the project’s administrator if you need access.</p>
      <ButtonPrimary to="/">Home</ButtonPrimary>
    </ErrorPage>
  );
};

export default AccessDeniedCard;
