import React from "react";

const getG = (name: string): React.ReactElement => {
  switch (name) {
    case "Canceled":
      return (
        <g fill="none">
        <circle
          transform="translate(-1)"
          cx="17"
          cy="16"
          r="15.5"
          fill="#E31937"
          stroke="#E31937"
        />
        <g transform="translate(6 6)">
        <path
          transform="rotate(-45 10 10)"
          id="a"
          d="M11 9h6a1 1 0 0 1 0 2h-6v6a1 1 0 0 1-2 0v-6H3a1 1 0 0 1 0-2h6V3a1 1 0 1 1 2 0v6z"
          strokeWidth="2.5"
          strokeLinejoin="round"
          strokeLinecap="round"
          stroke="#FFF"
        />
        </g>
      </g>
      );
    case "Completed":
      return (
        <g id="g1991" fillRule="evenodd" fill="none">
          <circle
            id="circle1987"
            fillRule="nonzero"
            fill="#44AA3C"
            r="16"
            cy="16"
            cx="16"
          />
          <path
            id="path1989"
            d="M9 18.076l3.712 3.713L22.79 11.712"
            strokeWidth="2.5"
            strokeLinejoin="round"
            strokeLinecap="round"
            stroke="#FFF"
          />
        </g>
      );
    case "In Progress":
      return (
        <g
          style={{
            fill: "#034691",
            fillOpacity: 1,
            stroke: "#034691",
            strokeOpacity: 1
          }}
          id="g8"
          fillRule="evenodd"
          fill="none"
        >
          <circle
            style={{
              fill: "#034691",
              fillOpacity: 1,
              stroke: "#034691",
              strokeOpacity: 1
            }}
            id="circle2"
            transform="translate(-1)"
            stroke="#E31937"
            fillRule="nonzero"
            fill="#E31937"
            r="15.5"
            cy="16"
            cx="17"
          />
        </g>
      );
    case "Not Required":
      return (
        <g fill="none" fillRule="evenodd">
          <circle
            cx="16"
            cy="16"
            r="14.084"
            fill="none"
            stroke="#e8e8ef"
            strokeOpacity=".94118"
            strokeWidth="3.7209"
          />
        </g>
      );
    case "Not Started":
      return (
        <g fill="none" fillRule="evenodd">
          <circle
            cx="16"
            cy="16"
            r="14.084"
            fill="none"
            stroke="#404040"
            strokeWidth="3.7209"
          />
        </g>
      );
    case "On Hold":
      return (
        <g fillRule="evenodd">
          <circle
            transform="translate(-1)"
            cx="17"
            cy="16"
            r="15.5"
            fill="#E31937"
            fillRule="nonzero"
            stroke="#E31937"
          />
          <text
            x="-1.9297504"
            y="4.8164062"
            fill="#ffffff"
            fontFamily="OpenSans-Bold"
            fontSize="16px"
            fontWeight="bold"
          >
            <tspan x="12.78125" y="21.816406" />
          </text>
          <g fill="#fff">
            <path d="m14.338 10.164 0.3947 8.3042 2.5447-0.0039 0.36842-8.2763z" />
            <rect x="14.422" y="19.754" width="3.1641" height="2.0664" />
          </g>
        </g>
      );

    default:
      return <g />;
  }
};

const SVGIcon = ({
  name = "",
  style = {},
  viewBox = "0 0 32 32",
  width = "100%",
  className = "",
  height = "100%"
}): React.ReactElement => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {getG(name)}
  </svg>
);

export default SVGIcon;