import React, { useState, ChangeEvent, useLayoutEffect, createRef } from "react";
import { JSONFindAll } from "../../../utilities/utilities";
import { logError } from "../../../utilities/errorReporting";
import searchIcon from "../../../assets/search.svg";
import closeIcon from "../../../assets/close.svg";
import sortAscendingIcon from "../../../assets/sort-ascending.svg";
import sortDescendingIcon from "../../../assets/sort-descending.svg";
import { activeFiltersType } from "../../DashboardPage/DashboardPage";
import ScrollToTopButton from "../ScrollToTopButton/ScrollToTopButton";
import "./ShowMoreList.scss";

/**
 * ShowMoreList.tsx
 *
 * This component renders a list of items (its children) with a "show more" button at the very bottom that allows the
 * user to view the list in small increments with an optional search bar for filtering.
 *
 * Props:
 *
 * data - an array of objects used to render each child element. Searches are performed against this array.
 * IDPropName - this is the name of the unique identifier which will be used for filtering/searching each item.
 * includeSearch - self-explanatory
 * searchPlaceholder - the placeholder text that appears in the search box. If not supplied, it will simply say "Search"
 * searchableKeys - an array of property names that will be searchable. These MUST be valid properties of items in your data array.
 *                  If a property is not included here, that property will never match any search queries. If this property is not supplied,
 *                  all properties will be searchable.
 * sortableKeys - an array of property names that the user will be able to sort on. When supplied, a "sort by" select will appear in the
 *                top bar. These keys are specified as an object of the form {key: "key", label: "My label"}. The key should be the actual
 *                name of the property you want to sort in the data, and the label is what appears in the sort-by select option.
 * incrementAmount - the number of new list items to display each time the user clicks "show more"
 * initialNumberDisplayed - self-explanatory. If not supplied, the incrementAmount is used.
 * noResultsFoundText - the text to display if a user types a search query that yields no results. If not supplied, it will simply
 *                      say "No results found".
 * emptyDataText - the text to display if the request to fetch data to populate the list comes back empty. if not supplied, it will
 *                 simply say "No results found".
 * controls - a React Element that will appear at the top of the showMoreList, beneath the search bar. This allows you to provide
 *            extra controls, such as a button to select all elements, or a custom filter/sort of some kind.
 * onSearch - this function is triggered when a user enters text into the search bar. This is useful if you need to trigger an action
 *            in the container when a search is performed.
 * className - self-explanatory
 *
 *  This component uses the ID, specified with IDPropName, of each child list item to determine which should appear
 *  when a filter is applied, meaning that an ID must be supplied to each child, and this id must match the id of
 *  the element in the data used to render this child.
 *
 * For example, given a data object:
 *
 *  data = [
 *    {
 *      id: "1",
 *      name: "Banjo"
 *    },
 *    {
 *      id: "2",
 *      name: "Kazooie"
 *    }
 * ];
 *
 * We would render a ShowMoreList as:
 *
 *  <ShowMoreList
 *    data={data}
 *    IDPropName="id"
 *    ... other props ...
 *  >
 *    <ListItemOne id="1" name="Banjo"/>
 *    <ListItemTwo id="2" name="Kazooie/>
 *  </ShowMoreList>
 *
 * To see an example of this component in action, see AddNewContactsModalContent.tsx 🕺
 */

interface ShowMoreListProps<DataType> {
  data?             : DataType[];
  IDPropName        : keyof DataType;
  includeSearch?    : boolean;
  searchPlaceholder?: string;
  // TODO: is it possible to type these two as "any property of DataType, or any properties of its values" ?
  // We used to have this, but we might get nested objects...
  // searchableKeys?: (keyof DataType)[];
  searchableKeys?        : string[];
  sortableKeys?          : ({ key: string; label: string })[];
  incrementAmount?       : number;
  initialNumberDisplayed?: number;
  noResultsFoundText?    : string;
  emptyDataText?         : string;
  controls?              : React.ReactElement;
  onSearch?              : () => void;
  className?             : string;
  plsFilterBy?: ({}:activeFiltersType) => void;
  activeFilters?: activeFiltersType;
  scrollToTopButton?: boolean;
}

function ShowMoreList<DataType>(
  props: React.PropsWithChildren<ShowMoreListProps<DataType>>
): React.ReactElement {
  const childArray             = React.Children.toArray(props.children);
  const defaultIncrementAmount = Math.max(5, childArray.length);

  const incrementAmount = props.incrementAmount
    ? props.incrementAmount
    : defaultIncrementAmount;

  const [numberDisplayed, setNumberDisplayed] = useState(
    props.initialNumberDisplayed
      ? props.initialNumberDisplayed
      : incrementAmount
  );
  const [removeFilter, setRemoveFilter] = useState({ name: "", status: false })
  const [searchValue, setSearchValue] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [sortedBy, setSortedBy] = useState(
    props.sortableKeys ? props.sortableKeys[1].key : ""
  );
  const [ascending, setAscending] = useState(true);
  const [sortFilterMenuOpen, setSortFilterMenuOpen] = useState(false);
  const activeFiltersMap = [
    {
      title: "Service Type",
      filters: [
        { name: "New Electric Service", status: false },
        { name: "New Gas Service", status: false}
      ]
    },
    {
      title: "In Progress",
      filters: [
        { name: "Getting Started", status: false},
        { name: "Collection of Documents", status: false},
        { name: "Initial Design & Site Meeting", status: false},
        { name: "Detailed Design", status: false},
        { name: "Customer Approval & Payment", status: false},
        { name: "Final Approval & Site Evaluation", status: false},
        { name: "Scheduling & Construction", status: false},
        { name: "Install Meter & Turn On", status: false},
      ]
    },
    // {
    //   title: "Date Received",
    //   filters: [
    //     { name: "Today", status: false },
    //     { name: "This Week", status: false },
    //     { name: "This Month", status: false },
    //     { name: "This Year", status: false },
    //   ]
    // }
  ]
  const [activeFilters, setActiveFilters] = useState(activeFiltersMap)
  const searchValueChangedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.onSearch) {
      props.onSearch();
    }

    const newSearchValue = event.target.value.toString().toLowerCase();

    if (newSearchValue.length) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }

    setSearchValue(newSearchValue);
  };

  const clearSearch = () => {
    setFilterApplied(false);
    setSearchValue("");
  };

  const handleShowMoreButtonClicked = () => {
    setNumberDisplayed(numberDisplayed + incrementAmount);
  };

  let showMoreButtonContainerContent = null;

  let searchBoxContents = null;

  let sortOptions = null;

  if (props.sortableKeys) {
    sortOptions = props.sortableKeys.map(item => {
      const isDisabled = item.label === 'Sort by' ? true : false;
      return (
        <option key={item.key} value={item.key} disabled={isDisabled}>
          {item.label}
        </option>
      );
    });
  }

  const sortControls = (
    <>
      <div
        className={`show-more-list__search-box__sort-filter-box ${
          sortFilterMenuOpen ? "never-open" : ""
        }`}
      >
        <div className="show-more-list__search-box__sort-filter-box__close-btn-container tab-to-trigger">
          <button
            type      = "button"
            className = "show-more-list__search-box__sort-filter-box__close-btn"
            onClick   = {() => setSortFilterMenuOpen(false)}
          >
            <img
              src        = {closeIcon}
              alt        = "Close filter menu"
              aria-label = "Close filter menu"
            />
          </button>
        </div>

        <button
          className   = "show-more-list__search-box__sort-direction-btn tab-to-trigger"
          type        = "button"
          data-testid = "sort-button"
          onClick     = {() => setAscending(!ascending)}
        >
          <img
            src = {ascending ? sortAscendingIcon : sortDescendingIcon}
            alt = {
              ascending
                ? "Sorted in ascending order"
                : "Sorted in descending order"
            }
          />
        </button>
        <select
          className   = "show-more-list__search-box__sort-dropdown tab-to-trigger"
          value       = {sortedBy}
          data-testid = "sort-select"
          onChange    = {event => {
            setSortedBy(event.target.value);
          }}
        >
          {props.sortableKeys ? sortOptions : null}
        </select>
      </div>
      <button
        className = "show-more-list__search-box__sort-filter-box__open-btn"
        type      = "button"
        onClick   = {() => setSortFilterMenuOpen(!sortFilterMenuOpen)}
      >
        {!sortFilterMenuOpen ? "Filter" : ("Close")}
      </button>
    </>
  );

  let mql = window.matchMedia('(max-width: 800px)');

  const [placeHolder, setPlaceHolder] = useState(props.searchPlaceholder && window.innerWidth > 800 ? props.searchPlaceholder: "Search" || "")
  mql.addListener(() => {
    let text: string = props.searchPlaceholder && window.innerWidth > 800 ? props.searchPlaceholder: "Search"
    setPlaceHolder(text);
  })

  if (props.includeSearch) {
    searchBoxContents = (
      <div className="show-more-list__search-box">
        <img
          className = "show-more-list__search-box__icon"
          src       = {searchIcon}
          alt       = "Search"
        />
        <input
          className   = "show-more-list__search-box__input"
          type        = "text"
          aria-label  = "search box"
          placeholder = {
            placeHolder
          }
          value    = {searchValue}
          onChange = {event => searchValueChangedHandler(event)}
        />
        <button
          onClick   = {clearSearch}
          type      = "button"
          tabIndex={filterApplied ? 0 : -1}
          className = {`show-more-list__search-box__clear-btn ${
            filterApplied ? "displayed": ""
          }`}
        >
          <img src={closeIcon} alt="Clear search" aria-label="Clear search" />
        </button>
        {props.sortableKeys ? sortControls : null}
      </div>
    );
  }

  const getMaxValueOfSortedProperty = (item: React.ReactNode) => {
    const itemElement = item as React.ReactElement;

    const id = itemElement.props[props.IDPropName] as string;

    if (props.data) {
      const data = props.data.filter(dataPoint => {
        return ((dataPoint[props.IDPropName] as unknown) as string) === id;
      });

      const values = JSONFindAll(data, [sortedBy]);
      const max    = values.sort()[values.length - 1];

      return max;
    }
    logError(
      "ShowMoreList.tsx: you are using this with sortable fields specified, but you did not specify the data prop - please supply the data to be sorted."
    );

    return null;
  };

  let childrenContent = childArray;

  if (props.sortableKeys) {
    childrenContent = childrenContent.sort((a, b) => {
      const aElement = a as React.ReactElement;
      const bElement = b as React.ReactElement;

      const aMax = getMaxValueOfSortedProperty(aElement);
      const bMax = getMaxValueOfSortedProperty(bElement);

      if (!!aMax && !!bMax && aMax < bMax) {
        return ascending ? -1 : 1;
      }
      if (!!aMax && !!bMax && aMax > bMax) {
        return ascending ? 1 : -1;
      }

      return 0;
    });
  }

  if (filterApplied) {
    // To filter the list, we find the corresponding data element that matches this child's ID,
    // we then use JsonFind to search this data element for all properties specified - this is a deep search.
    // This gives us an object, where each property is a search query, and each value is its result.
    // We then simply check that at least one of these values contains the filter text.
    childrenContent = childrenContent.filter(child => {
      const childElement = child as React.ReactElement;

      if (
        childElement.props &&
        childElement.props[props.IDPropName] &&
        props.data
      ) {
        const id = childElement.props[props.IDPropName] as string;

        const childData = props.data.filter(item => {
          return ((item[props.IDPropName] as unknown) as string) === id;
        });

        if (props.searchableKeys) {
          const searchResults = JSONFindAll(childData, props.searchableKeys);

          return Object.values(searchResults).some(result => {
            const stringifiedResult = JSON.stringify(result).toLowerCase();
            return stringifiedResult.indexOf(searchValue) > -1;
          });
        }

        // If no searchableKeys are specified, we simply check every property of the object
        return Object.values(childData[0]).some(item => {
          return (
            JSON.stringify(item)
              .toLowerCase()
              .indexOf(searchValue) > -1
          );
        });
      }

      if (!childElement.props[props.IDPropName]) {
        logError(
          `ShowMoreList.tsx: children passed to ShowMoreList must have a valid id prop. You specified "${
            props.IDPropName
          }", but this is not a valid prop of this list's children. Check that the children of this ShowMoreList actually have the property ${
            props.IDPropName
          }. `
        );
      }

      if (!props.data) {
        logError(
          "ShowMoreList.tsx: you are using this component with the optional search bar, but you did not specify the data prop - please supply the data to be searched."
        );
      }

      logError(
        "ShowMoreList.tsx: an unexpected error occurred while searching."
      );

      return true;
    });
  }

  let noProjectsFoundText = null;

  const noResultsFound = filterApplied && childrenContent.length === 0;

  if (noResultsFound) {
    noProjectsFoundText = (
      <div className="show-more-list__no-results-text">
        {props.noResultsFoundText
          ? props.noResultsFoundText
          : "You currently have no active projects or work orders."}
      </div>
    );
  } else if (!filterApplied && childrenContent.length === 0) {
    noProjectsFoundText = (
      <div className="show-more-list__no-results-text">
        {props.emptyDataText ? props.emptyDataText : "You currently have no active projects or work orders."}
      </div>
    );
  }

  if (!filterApplied && numberDisplayed < childrenContent.length) {
    showMoreButtonContainerContent = (
      <div className="show-more-list__button-container">
        <div className="show-more-list__button-container__count">
          Showing {window.innerWidth < 800 ? <br/> : ""}<strong>{numberDisplayed}</strong> of <strong>{props.data && props.data.length}</strong>
        </div>
        <button
          className = "show-more-list__button-container__button"
          type      = "button"
          onClick   = {handleShowMoreButtonClicked}
        >
          Show more
        </button>
        {/* this div is for alignment */}
        <div className="show-more-list__button-container__count"/>
      </div>
    );
  }

  let listContent = noProjectsFoundText;

  const projectsFound = !noProjectsFoundText;

  if (projectsFound) {
    listContent = (
      <>
        {props.controls}
        <div className="show-more-list__content__items">
          {childrenContent.slice(0, numberDisplayed)}
        </div>
        {showMoreButtonContainerContent}
      </>
    );
  }

  const doFilter = (i: { name: string, status: boolean}) => {
    // For reference: see activeFiltersMap definition (above)
    // > Find the relevant filter set title from activeFiltersMap based on the name of the filter
    const relevantFilterSetTitle = activeFilters.filter(a => a.filters.filter(f => f.name === i.name).length > 0)[0].title
    // > Toggle the relevant filter and set other filters within the set to false (radio button functionality)
    let updatedFilter = activeFilters.filter(a => a.title === relevantFilterSetTitle && a.filters.map(
      f => f.status = f.name === i.name ? !f.status : false ))[0]
    // > Combine the changed filter set with the unchanged filter set(s)
    let updatedFilters = activeFilters.map(a => a.title === relevantFilterSetTitle ? updatedFilter : a)
    setActiveFilters(updatedFilters)
    // > Apply filter set to projectsList in DashboardPage, don't fool with the filtering here.
    props.plsFilterBy && props.plsFilterBy(activeFilters)
  }

  const filtersApplied = activeFilters.map(af => af.filters.filter(aff => aff.status)).flat()

  const plusIcon = (
    <div className="filter-box-new__dropdown-wrapper__icon">
      <div className="filter-box-new__dropdown-wrapper__plus-sign">+</div>
    </div>
  )

  useLayoutEffect(() => {
    if(removeFilter.name.length) {
      doFilter(removeFilter);
      setRemoveFilter({ name: "", status: false })
      document.getElementsByClassName("fader")[0].classList.remove("fader");
    }

  }, [removeFilter])

  let itsEmptyRef = createRef<HTMLDivElement>()
  let filterBox = () =>
      <div className="filter-box-new">
        <div className={`filter-box-new__dropdown-wrapper `}>

        <div className={`active-pill-container-wrapper ${!sortFilterMenuOpen && filtersApplied.length > 0  ? "active-fadeIn" : "fadeOut"}`} ref={itsEmptyRef}>
          <div className={`filter-box-new__dropdown-wrapper__active-pills`}>
            { !sortFilterMenuOpen && filtersApplied.length > 0 && filtersApplied.map(fa => <div
              className="filter-box-new__dropdown-wrapper__pill active"
              onClick={
                (e) => {
                  if(filtersApplied.length <= 1 && itsEmptyRef.current) {
                    itsEmptyRef.current.classList.add("closing")
                  }
                  setTimeout(() => {
                    doFilter(fa)
                  }, 550)
                }}
                >{fa.name}&nbsp;<div className="filter-box-new__dropdown-wrapper__x-sign">x</div> </div>
            )}
          </div>
        {!sortFilterMenuOpen && filtersApplied.length > 0  ?
        (
          <span
            className="clear-all"
            tabIndex={0}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                if(itsEmptyRef.current) {
                  itsEmptyRef.current.classList.add("closing")
                }
                setTimeout(() => {
                  filtersApplied.map(fa => doFilter(fa) )
                }, 550)
              }
            }}
            onClick={() => {
              if(itsEmptyRef.current) {
                itsEmptyRef.current.classList.add("closing")
              }
              setTimeout(() => {
                filtersApplied.map(fa => doFilter(fa) )
              }, 550)
            }}
          >Clear All</span>
        ) : null}
        </div>

        <div className={`filter-box-new__dropdown-wrapper__container ${!sortFilterMenuOpen ? "fadeOut" : "fadeIn"}`}>
          { activeFilters.map(af =>
            <div key={af.title.split(" ").join('')} className="filter-box-new__dropdown-wrapper__itemContainer" id={af.title.split(" ").join('')}>
              <div className="filter-box-new__dropdown-wrapper__title">{af.title}</div>
              { af.filters.map((f,i) =>
                  <div
                    tabIndex={0}
                    key={f.name}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        doFilter(f);
                      }
                    }}
                    className={`filter-box-new__dropdown-wrapper__pill ${f.status ? `active` : ``}`}
                    onClick={() => {
                      doFilter(f)
                    }}>
                    { af.title==="In Progress" && <span>{(i+1)+':'}&nbsp;</span>}
                    { f.name }
                    { f.status ? <div className="filter-box-new__dropdown-wrapper__x-sign">x</div> : plusIcon}
                  </div>
                )}
            </div>
          )}
        </div>

      </div>
    </div>

  return (
    <>
      <div
        className={
          props.className ? `${props.className} show-more-list` : "show-more-list"
        }
      >
        {searchBoxContents}
      </div>
      {filterBox()}
      <div className="show-more-list__content">{listContent}
        {props.scrollToTopButton && <ScrollToTopButton />}
      </div>
    </>
  );
}

export default ShowMoreList;